// error message in case of input
export default function errorMessage(airportFrom, airportTo, distance) {
    if (!airportFrom && !airportTo) {
        return 'Select both cities / airports';
    }
    if (!airportFrom) {
        return 'Select origin';
    }
    if (!airportTo) {
        return 'Select destination';
    }
    if (!distance) {
        return 'Distance not found';
    }
    return '';
}
