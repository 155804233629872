import { TextField } from '@mui/material';
import React from 'react';
import AutocompleteSelect from '../../../components/atom/AutoCompleteSelect';
import { esgCategoryAutocomplete } from '../../../autocomplete';
import { useEsgFilters } from '../../../hooks/useEsgFilters';

function EsgCategoryFilter() {
    const { esgCategory, setEsgFilters, setEdited } = useEsgFilters();

    return (
        <AutocompleteSelect
            initCall={esgCategoryAutocomplete.init}
            autocompleteCall={esgCategoryAutocomplete.search}
            value={esgCategory}
            setValue={(ev, getItem) => {
                const item = getItem(ev);
                setEsgFilters({
                    esgCategoryFilter: item?.id || null,
                    esgCategoryNameFilter: item?.name || null,
                    esgSubcategoryFilter: null,
                    esgDisclosureFilter: null,
                    esgQuestionFilter: null,
                });
                setEdited(false);
            }}
        >
            <TextField label="ESG Category" fullWidth size="small" />
        </AutocompleteSelect>
    );
}

export default EsgCategoryFilter;
