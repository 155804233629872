/**
 * Validate esgs
 * @param values values
 */
export default function validateEsg(values) {
    const errors = {};

    if (values.from_date && !values.to_date) {
        errors.to_date = 'To date is required';
    }

    if (!values.from_date && values.to_date) {
        errors.from_date = 'From date is required';
    }

    if (values.from_date && values.to_date) {
        if (new Date(values.from_date) > new Date(values.to_date)) {
            errors.from_date = 'From date cannot be later than to date';
            errors.to_date = 'To date cannot be earlier than from date';
        }
    }

    if (
        values.from_date &&
        (new Date(values.from_date) < new Date('2000-01-01') ||
            new Date(values.from_date) > new Date('2050-01-01'))
    ) {
        errors.from_date = 'Invalid date (date should be between 2000 and 2050)';
    }
    if (
        values.to_date &&
        (new Date(values.to_date) < new Date('2000-01-01') ||
            new Date(values.to_date) > new Date('2050-01-01'))
    ) {
        errors.to_date = 'Invalid date (date should be between 2000 and 2050)';
    }

    return errors;
}
