import {
    Button,
    Grid,
    IconButton,
    TableCell,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

function NoteLineEdit({ noteEdit, setNote, setNoteEdit, id, note = '', currentPath, setShowNote }) {
    const noteFieldRef = useRef();
    useEffect(() => {
        noteFieldRef.current?.focus();
    }, [noteEdit === `${id}-${currentPath}`, noteFieldRef.current]);

    return noteEdit === `${id}-${currentPath}` ? (
        <TextField
            inputRef={noteFieldRef}
            name={note}
            fullWidth
            size="small"
            autoComplete="off"
            label="Note"
            value={note}
            onChange={(ev) => setNote(ev.target.value)}
            onBlur={() => {
                if (note === '') {
                    setShowNote(false);
                }
                setNoteEdit(false);
            }}
            disabled={noteEdit !== `${id}-${currentPath}`}
            sx={{
                m: 3.75,
                width: '100%',
            }}
        />
    ) : (
        <>
            <Typography variant="body3">{note}</Typography>
            <IconButton size="small" onClick={() => setNoteEdit(`${id}-${currentPath}`)}>
                <EditIcon />
            </IconButton>
        </>
    );
}

function ScenarioLine({ id, name, navigateToChildren, note, setNote, currentPath }) {
    const [noteEdit, setNoteEdit] = useState(false);
    const [showNote, setShowNote] = useState(!!note);

    const isNoteEditing = noteEdit === `${id}-${currentPath}`;

    return (
        <>
            <TableRow key={id} className="removeBorderBottom">
                <TableCell width="100%">
                    <span
                        style={{ cursor: navigateToChildren ? 'pointer' : 'auto' }}
                        onClick={navigateToChildren === false ? () => {} : navigateToChildren}
                    >
                        <Typography variant="body1">{name}</Typography>
                    </span>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={1}>
                    <Grid
                        item
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={isNoteEditing ? 1 : 0}
                    >
                        {!showNote ? (
                            <Grid item>
                                <Button
                                    variant="text"
                                    size="small"
                                    startIcon={<AddIcon />}
                                    onClick={() => {
                                        setShowNote(true);
                                        setNoteEdit(`${id}-${currentPath}`);
                                    }}
                                >
                                    Note
                                </Button>
                            </Grid>
                        ) : (
                            <NoteLineEdit
                                noteEdit={noteEdit}
                                setNote={setNote}
                                setNoteEdit={setNoteEdit}
                                id={id}
                                note={note}
                                currentPath={currentPath}
                                setShowNote={setShowNote}
                            />
                        )}
                    </Grid>
                </TableCell>
            </TableRow>
        </>
    );
}

export default ScenarioLine;
