import { IconButton, Toolbar, Grid, Badge } from '@mui/material';
import { Menu, ChevronLeft } from '@mui/icons-material';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Drawer, DrawerHeader, AppBar } from './Drawer';
import SidebarMenu from './SidebarMenu';
import GlobalYearFilter from './GlobalYearFilter';
import ProfileAvatar from './ProfileAvatar';
import { useAuth } from '../../hooks/useAuth';
import BreadcrumbFilters from './BreadcrumbFilters';
import SiteAndTagFilter from './SiteAndTagFilter';
import DarkModeSwitch from './DarkModeSwitch';
import { useNotifications } from '../../hooks/useNotifications';
import { BACKEND_URL, NORTH_STAR_CARBON_MANAGEMENT_URL } from '../../config';
import GuideBoxSidebar from './GuideBoxSidebar';
import { useGuideBoxSidebar } from '../../hooks/useGuideBoxSidebar';

const Header = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const { notifications } = useNotifications();
    const { sidebarOpen, closeSidebar } = useGuideBoxSidebar();
    const [open, setOpen] = useState(true);
    const handleDrawerOpen = useCallback(() => {
        setOpen(true);
    }, [setOpen]);

    const handleDrawerClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const isSurvey = window.location.pathname.includes('survey/'); // TODO: need a better solution
    const isEsg = window.location.pathname.includes('/esgs'); // TODO: need a better solution

    useEffect(() => {
        setOpen(!!user && !isSurvey);
    }, [user]);

    const companyLogo = !user?.companyLogo
        ? '/logo-white.svg'
        : `${BACKEND_URL}/s3proxy?url=${encodeURIComponent(user.companyLogo)}`;

    return (
        <>
            <AppBar position="fixed" open={open}>
                <Toolbar>
                    {user && !isSurvey ? (
                        <IconButton
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{
                                mr: 5,
                            }}
                            style={{
                                ...(open && { display: 'none' }),
                            }}
                        >
                            <Menu />
                        </IconButton>
                    ) : null}
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item style={{ flex: '1 1 0' }}>
                            {isSurvey ? (
                                <a
                                    href={NORTH_STAR_CARBON_MANAGEMENT_URL}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img
                                        alt="NorthStar"
                                        src="/logo-white.svg"
                                        height="40px"
                                        style={{ paddingTop: '5px', marginBottom: '-3px' }}
                                    />
                                </a>
                            ) : (
                                <Link to="/datalines">
                                    <img
                                        alt="NorthStar"
                                        src={companyLogo}
                                        height="40px"
                                        style={{ paddingTop: '5px', marginBottom: '-3px' }}
                                    />
                                </Link>
                            )}
                        </Grid>
                        {user && !isSurvey && <BreadcrumbFilters isEsg={isEsg} />}
                        {user && !isSurvey && <SiteAndTagFilter />}
                        {user && !isSurvey && (
                            <Grid item>
                                <GlobalYearFilter />
                            </Grid>
                        )}
                        <Grid item>
                            <DarkModeSwitch />
                        </Grid>
                        {user && !isSurvey && (
                            <Grid item>
                                <Badge
                                    badgeContent={notifications?.total || 0}
                                    color="error"
                                    overlap="circular"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => navigate('/notifications')}
                                >
                                    <IconButton>
                                        <NotificationsIcon />
                                    </IconButton>
                                </Badge>
                            </Grid>
                        )}
                        {user && !isSurvey && (
                            <Grid item>
                                <ProfileAvatar />
                            </Grid>
                        )}
                    </Grid>
                    {user && !isSurvey && sidebarOpen ? (
                        <Drawer variant="permanent" anchor="right" open>
                            <GuideBoxSidebar setMenuOpen={setOpen} closeSidebar={closeSidebar} />
                        </Drawer>
                    ) : null}
                </Toolbar>
            </AppBar>
            {user && !isSurvey ? (
                <Drawer variant="permanent" open={open}>
                    <DrawerHeader>
                        <IconButton onClick={handleDrawerClose}>
                            <ChevronLeft />
                        </IconButton>
                    </DrawerHeader>
                    <SidebarMenu open={open} />
                </Drawer>
            ) : null}
        </>
    );
};

export default Header;
