import {
    Alert,
    Chip,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { convertUnitGToKg, formatNumber } from '../../helpers/convertUnits';
import { getLevelHierarchy } from '../../helpers/masterSourceImport';

const ConfirmationImportMasterSourcesModalRow = ({ item, idx, rowRefs }) => {
    const [open, setOpen] = useState(false);
    // observer to see if the ref is visible
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setOpen((oldOpen) => {
                            if (oldOpen === false) {
                                // console.log(`Rendered row ${idx}`);
                            }
                            return true;
                        });
                    }
                });
            },
            { threshold: 0 }
        );
        if (rowRefs.current[idx]) {
            observer.observe(rowRefs.current[idx]);
        }
        return () => {
            if (rowRefs.current[idx]) {
                observer.unobserve(rowRefs.current[idx]);
            }
        };
    }, [rowRefs.current[idx]]);

    if (!open) {
        return (
            <TableRow
                ref={(el) => {
                    rowRefs.current[idx] = el;
                }}
                colSpan={8}
                style={{ height: 200 }}
            />
        );
    }
    return (
        <>
            <TableRow
                ref={(el) => {
                    rowRefs.current[idx] = el;
                }}
            >
                <TableCell>
                    <Chip
                        size="small"
                        color={!item.source_id ? 'primary' : 'default'}
                        label={!item.source_id ? 'New' : `#${item.source_id}`}
                    />{' '}
                    {item.source_name}
                </TableCell>
                <TableCell>
                    <Chip
                        size="small"
                        color={!item.subcategory_id ? 'primary' : 'default'}
                        label={!item.subcategory_id ? 'New' : `#${item.subcategory_id}`}
                    />{' '}
                    {item.subcategory}
                </TableCell>
                <TableCell>
                    <Chip
                        size="small"
                        color={!item.category_id ? 'primary' : 'default'}
                        label={!item.category_id ? 'New' : `#${item.category_id}`}
                    />{' '}
                    {item.category}
                </TableCell>
                <TableCell>{item.scope}</TableCell>
                <TableCell>{item.source}</TableCell>
                <TableCell>{item.admin_note}</TableCell>
                <TableCell>{item.guide_note}</TableCell>
                <Tooltip title={getLevelHierarchy(item)} placement="left">
                    <TableCell>{item.parent_source_name || '-'}</TableCell>
                </Tooltip>
            </TableRow>
            <TableRow>
                <TableCell />
                <TableCell colSpan={4}>
                    {item.error.length > 0 && (
                        <Alert severity="error" sx={{ mb: 1 }}>
                            {item.error.map((line, idx) => {
                                return <div key={idx}>{line}</div>;
                            })}
                        </Alert>
                    )}
                    <Table size="small">
                        <TableHead>
                            <TableCell>Location</TableCell>
                            <TableCell>
                                CO
                                <sub>2</sub> <small>kg</small>
                            </TableCell>
                            <TableCell>
                                CH
                                <sub>4</sub> <small>g</small>
                            </TableCell>
                            <TableCell>
                                N<sub>2</sub>O <small>g</small>
                            </TableCell>
                        </TableHead>
                        <TableBody>
                            {Object.entries(item.locations).map(([locId, locData]) => {
                                return (
                                    <TableRow key={locId}>
                                        <TableCell>{locData.location}</TableCell>
                                        <TableCell>
                                            {formatNumber(
                                                convertUnitGToKg(locData.co2Factor.toFixed(5))
                                            )}
                                        </TableCell>
                                        <TableCell>{formatNumber(locData.ch4Factor)}</TableCell>
                                        <TableCell>{formatNumber(locData.n2oFactor)}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableCell>
            </TableRow>
        </>
    );
};

export default ConfirmationImportMasterSourcesModalRow;
