import { Autocomplete, CircularProgress, FormControl, Grid, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CommonButton from '../../../components/atom/CommonButton';
import { doSwaggerCall } from '../../../hooks/useApi';
import errorMessage from '../helpers/airportHelpers';

const AirportDistanceCalculator = ({
    setAmount,
    setFieldValue,
    notes = null,
    setShowNotes,
    closedYear,
}) => {
    const [airportFrom, setAirportFrom] = useState(null);
    const [airportTo, setAirportTo] = useState(null);
    const [distance, setDistance] = useState(null);
    const [allAirports, setAllAirports] = useState([]);
    const airportListLoading = allAirports.length === 0;

    // runs once when the component is mounted
    useEffect(() => {
        // TODO: fix limit
        doSwaggerCall('AirportDistances', 'getAirportList', { limit: 1000000 }).then((res) => {
            const { airports } = res;
            setAllAirports(airports);
        });
    }, []);

    // calculate distance if both airports are selected
    useEffect(() => {
        if (!airportFrom || !airportTo) {
            return;
        }

        doSwaggerCall('AirportDistances', 'getOneDistance', {
            fromAirport: airportFrom.short,
            toAirport: airportTo.short,
        })
            .then((res) => {
                setDistance(res.distance);
            })
            .catch(() => {
                // no distance found
                setDistance(null);
            });
    }, [airportFrom, airportTo]);

    return (
        <Grid container item xs={12}>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <Autocomplete
                        disabled={closedYear}
                        options={allAirports}
                        fullWidth
                        getOptionLabel={(option) => {
                            const label = option.city
                                ? `${option.short} - (${option.city})`
                                : option.short;

                            return option.short ? label : '';
                        }}
                        filterOptions={(options, state) => {
                            const searchTerm = state.inputValue?.toLowerCase();
                            return options.filter(
                                (option) =>
                                    option.short?.toLowerCase().includes(searchTerm) ||
                                    option.city?.toLowerCase().includes(searchTerm)
                            );
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Origin Airport"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {params.InputProps.endAdornment}
                                            {airportListLoading && (
                                                <CircularProgress color="inherit" size={15} />
                                            )}
                                        </>
                                    ),
                                }}
                            />
                        )}
                        onChange={(_, newValue) => {
                            setAirportFrom(newValue);
                            setDistance(null);
                        }}
                        value={airportFrom}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <Autocomplete
                        disabled={closedYear}
                        options={allAirports}
                        fullWidth
                        getOptionLabel={(option) => {
                            const label = option.city
                                ? `${option.short} - (${option.city})`
                                : option.short;

                            return option.short ? label : '';
                        }}
                        filterOptions={(options, state) => {
                            const searchTerm = state.inputValue?.toLowerCase();
                            return options.filter(
                                (option) =>
                                    option.short?.toLowerCase().includes(searchTerm) ||
                                    option.city?.toLowerCase().includes(searchTerm)
                            );
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Destination Airport"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {params.InputProps.endAdornment}
                                            {airportListLoading && (
                                                <CircularProgress color="inherit" size={15} />
                                            )}
                                        </>
                                    ),
                                }}
                            />
                        )}
                        onChange={(_, newValue) => {
                            setAirportTo(newValue);
                            setDistance(null);
                        }}
                        value={airportTo}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={3} sx={{ alignSelf: 'center', textAlign: 'center' }}>
                <b>Distance:</b>{' '}
                {distance ? `${distance} miles` : errorMessage(airportFrom, airportTo, distance)}
            </Grid>
            <Grid item xs={3} sx={{ alignSelf: 'center', textAlign: 'center' }}>
                <CommonButton
                    fullWidth
                    label="Set Distance as Usage"
                    buttonType="outlined"
                    disabled={!distance || closedYear}
                    onClick={() => {
                        setAmount(distance);
                        setShowNotes(true);
                        setFieldValue(
                            'notes',
                            `${
                                notes
                                    ? `${notes} - Distance between ${airportFrom.short} and ${airportTo.short} is ${distance} miles.`
                                    : `Distance between ${airportFrom.short} and ${airportTo.short} is ${distance} miles.`
                            }`
                        );
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default AirportDistanceCalculator;
