import React from 'react';
import { Checkbox, FormControlLabel, Tooltip } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CustomTreeItem from '../../atom/CustomTreeItem';
import { useDarkMode } from '../../../hooks/useDarkMode';

function EsgQuestionLevel({ checkedData, esg_question, setCheckedData, isScenario = false }) {
    const { darkMode } = useDarkMode();
    return (
        <CustomTreeItem
            nodeId={`esg_question - ${esg_question.id}`}
            label={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <FormControlLabel
                        label={esg_question.name}
                        labelPlacement="end"
                        control={
                            <Checkbox
                                checked={
                                    !!checkedData.find(
                                        (c) => c.id === esg_question.id && c.type === 'question'
                                    )
                                }
                                onChange={() => {
                                    const removeReqQuestion = checkedData.find(
                                        (item) =>
                                            item.id === esg_question.id && item.type === 'question'
                                    );
                                    setCheckedData(
                                        removeReqQuestion
                                            ? checkedData.filter(
                                                  (data) => data !== removeReqQuestion
                                              )
                                            : [
                                                  ...checkedData,
                                                  {
                                                      type: 'question',
                                                      id: esg_question.id,
                                                  },
                                              ]
                                    );
                                }}
                            />
                        }
                    />
                    {isScenario && (
                        <Tooltip
                            title={
                                esg_question.section?.fields?.length > 0 ? (
                                    <ul style={{ paddingLeft: '20px', margin: 0 }}>
                                        {esg_question.section.fields.map((field) => (
                                            <li key={field.id}>{field.label}</li>
                                        ))}
                                    </ul>
                                ) : (
                                    'No questions found'
                                )
                            }
                            sx={{ cursor: 'pointer' }}
                            placement="right"
                        >
                            <HelpOutlineIcon
                                style={{
                                    fontSize: '15px',
                                    color: !darkMode ? 'grey' : 'white',
                                }}
                            />
                        </Tooltip>
                    )}
                </div>
            }
        />
    );
}

export default EsgQuestionLevel;
