import {
    Grid,
    IconButton,
    InputAdornment,
    TableCell,
    TableRow,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import React, { useState } from 'react';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import EsgQuestionField from '../../../modals/components/esg/EsgQuestionField';
import EsgLevelNote from './EsgLevelNote';
import { WITHOUT_UNIT } from '../../../helpers/esgUnitConversions';
import { doSwaggerCall } from '../../../hooks/useApi';
import useNotify from '../../../hooks/useNotify';

const EsgScenarioLine = ({
    item,
    idx,
    setEsgNote,
    currentPath,
    questionName,
    setTarget,
    baselineYear,
    targetYear,
}) => {
    const [showEsgNote, setShowEsgNote] = useState(!!item.esgNote);
    const [esgNoteEdit, setEsgNoteEdit] = useState(false);
    const [generatingSuggestion, setGeneratingSuggestion] = useState(false);
    const { notifyError } = useNotify();

    async function generateSuggestion(question, idx) {
        try {
            setGeneratingSuggestion(idx);
            const res = await doSwaggerCall(
                'Esgs',
                'getEsgSuggestion',
                {},
                {
                    question: questionName,
                    field: question,
                }
            );

            const newValues = [...item.values];
            newValues[idx].targetValue = res.suggestion;
            setTarget(newValues);
        } catch (err) {
            notifyError(err);
        } finally {
            setGeneratingSuggestion(false);
        }
    }

    return (
        <React.Fragment key={item.id}>
            <TableRow className="removeBorderBottom">
                <TableCell>
                    <Grid alignItems="center" sx={{ display: 'flex' }}>
                        {questionName}
                    </Grid>
                </TableCell>
                <TableCell>{item.site_name}</TableCell>
                <TableCell />
            </TableRow>
            <TableRow className="removeBorderBottom">
                <TableCell colSpan={3}>
                    <EsgLevelNote
                        idx={idx}
                        esgNoteEdit={esgNoteEdit}
                        item={item}
                        showEsgNote={showEsgNote}
                        setShowEsgNote={setShowEsgNote}
                        setEsgNoteEdit={setEsgNoteEdit}
                        setEsgNote={setEsgNote}
                        currentPath={currentPath}
                    />
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={3}>
                    <Grid
                        container
                        spacing={0.5}
                        item
                        xs={12}
                        sx={{
                            border: '1px solid #e0e0e0',
                            borderRadius: '4px',
                            p: 1,
                            position: 'relative',
                        }}
                    >
                        <Grid container item xs={12} sx={{ mb: 1 }}>
                            <Grid item xs={6} sx={{ textAlign: 'center' }}>
                                <span style={{ float: 'left', marginLeft: '40%' }}>
                                    <Typography variant="body4">{baselineYear}</Typography>
                                </span>
                            </Grid>
                            <Grid item xs={6} sx={{ textAlign: 'center' }}>
                                <span style={{ float: 'right', marginRight: '40%' }}>
                                    <Typography variant="body4">{targetYear}</Typography>
                                </span>
                            </Grid>
                        </Grid>
                        {item.values.map((value, idx) => (
                            <Grid container item xs={12} key={idx}>
                                <Grid item xs={6} sx={{ opacity: 0.5 }}>
                                    <EsgQuestionField
                                        id={value.id}
                                        label={value.question}
                                        type="text" // should change later?
                                        defaultMeasure={value.measure}
                                        idx={idx}
                                        values={item}
                                        setFieldValue={() => {}}
                                        isScenario
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Tooltip title={value.question}>
                                        <Grid item xs={12} key={idx}>
                                            <TextField
                                                fullWidth
                                                multiline
                                                label={value.question}
                                                type="text"
                                                value={value.targetValue}
                                                onChange={(ev) => {
                                                    const newValues = [...item.values];
                                                    newValues[idx].targetValue = ev.target.value;
                                                    setTarget(newValues);
                                                }}
                                                InputLabelProps={{ shrink: true }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            {value.measure === WITHOUT_UNIT
                                                                ? null
                                                                : value.measure}

                                                            {generatingSuggestion !== idx ? (
                                                                <IconButton
                                                                    disabled={
                                                                        value.measure !==
                                                                        WITHOUT_UNIT
                                                                    }
                                                                    sx={{ p: 0 }}
                                                                    disableRipple
                                                                    onClick={() =>
                                                                        generateSuggestion(
                                                                            value.question,
                                                                            idx
                                                                        )
                                                                    }
                                                                >
                                                                    <AutoFixHighIcon />
                                                                </IconButton>
                                                            ) : (
                                                                <div>...</div>
                                                            )}
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
};

export default EsgScenarioLine;
