import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

export const TABLE_HEADERS = [
    { id: 'name', label: 'Name' },
    { id: 'industry', label: 'Industry' },
    { id: 'location', label: 'Location' },
    { id: 'co2e', label: 'CO₂e (mt)' },
    { id: 'netzero', label: 'Netzero' },
    { id: 'scopes', label: 'Scopes', sortable: false },
    { id: 'status', label: 'Status', sortable: false },
];

export const checkClosedStatus = (row) => {
    if (row.reviewedBy && row.datalineId && row.reviewedBy !== null && row.datalineId !== null) {
        return 'closed';
    }
    return 'opened';
};

export const isNetZero = (netzero) => {
    if (netzero === 1) {
        return (
            <span style={{ display: 'flex', justifyContent: 'center' }}>
                <CheckIcon color="success" />
            </span>
        );
    }
    if (netzero === 0) {
        return (
            <span style={{ display: 'flex', justifyContent: 'center' }}>
                <CloseIcon color="error" />
            </span>
        );
    }
    return '?';
};
