import { read, utils } from 'xlsx';
import { doSwaggerCall } from '../hooks/useApi';

// Parse xlsx sheets
const parseXlsxSheets = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);
        fileReader.onload = (e) => {
            const bufferArray = e?.target.result;
            const wb = read(bufferArray, { type: 'buffer' });

            // [ { sheet: 'name', data: [...] } ]
            const result = [];
            for (let i = 0; i < wb.SheetNames.length; i++) {
                // Internal structure, Frameworks tabs (in excel)
                const sheetData = utils.sheet_to_json(wb.Sheets[wb.SheetNames[i]], {
                    raw: false, // Keep dates as Date objects
                });
                // check empty sheets
                if (sheetData.length === 1) {
                    let emptyRow = true;
                    Object.entries(sheetData[0]).forEach((attr) => {
                        if (attr[1] !== '') {
                            emptyRow = false;
                        }
                    });
                    if (emptyRow) {
                        continue;
                    }
                }
                result.push({
                    name: wb.SheetNames[i],
                    data: sheetData,
                });
            }
            return resolve(result);
        };
    });
};

// Handle import
export default async function handleImport(file, esgQuestion, year, dryRun) {
    const xlsxFile = await parseXlsxSheets(file);
    const allImportLines = xlsxFile
        .map((sheet) => {
            // skip Sites, Users, Unit conversions and Tags sheets, these are just helper sheets
            if (
                sheet.name === 'Sites' ||
                sheet.name === 'Users' ||
                sheet.name === 'Unit conversions' ||
                sheet.name === 'Tags'
            ) {
                return [];
            }
            return [
                ...sheet.data.map((item) => {
                    return {
                        ...item,
                    };
                }),
            ];
        })
        .reduce((all, item) => [...all, ...item], []);
    // Import in 3 steps: check data (dryRun) - show data on confirmation modal - run import (!dryRun)
    const importedEsgs = await doSwaggerCall(
        'Esgs',
        'importEsgs',
        { esgQuestionId: esgQuestion, year, dryRun },
        { allImportLines }
    );
    return importedEsgs;
}
