import React, { useState } from 'react';
import {
    Grid,
    Paper,
    InputLabel,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAuth } from '../../hooks/useAuth';
import { useGlobalFilters } from '../../hooks/useGlobalFilters';
import useNotify from '../../hooks/useNotify';
import { doSwaggerCall } from '../../hooks/useApi';
import ScenarioSiteFilter from '../scenarios/components/targetpicker/components/ScenarioSiteFilter';
import CommonButton from '../../components/atom/CommonButton';
import PageWithTitle from '../../components/atom/PageWithTitle';
import recursiveChildrenForEsgScenario from './helpers/recursiveChildrenForEsgScenario';
import { cutEsgTree } from './helpers/targetPickerEsgHelperFunctions';
import EsgTargetPicker from './components/EsgTargetPicker';
import BasicScenarioSettings from '../scenarios/components/BasicScenarioSettings';
import FilterComponent from '../../components/atom/FilterComponent';

const validateSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    baseline_year: Yup.number().min(2000).required('Required'),
    target_year: Yup.number()
        .min(2000)
        .required('Required')
        .moreThan(Yup.ref('baseline_year'), 'Target year is not greater than baseline year!'),
});

function AddEsgScenarioPage() {
    const { user } = useAuth();
    const { availableYears } = useGlobalFilters();
    const navigate = useNavigate();
    const { notifyError, notifySuccess } = useNotify();
    const [selectedTargetData, setSelectedTargetData] = useState([]);
    const [siteFilter, setSiteFilter] = useState([]);
    const [esgTagId, setEsgTagId] = useState(0);

    const defaultScenario = {
        name: 'New ESG Scenario',
        company_id: user.company_id,
        baseline_year:
            availableYears.filter((e) => e.state === 'closed')[0]?.year ||
            new Date().getFullYear() - 1,
        target_year: new Date().getFullYear() + 9,
        esg_tag_id: 0,
        site_filter: [],
    };

    const onSubmit = async (values) => {
        try {
            // checkbox array validation
            if (selectedTargetData.length === 0) {
                notifyError('You should pick at least one target data!');
                return;
            }
            const res = await doSwaggerCall('EsgQuestions', 'getEsgQuestionHierarchy', {
                year: values.baseline_year,
                hasanyesgs: true,
                siteIdList: siteFilter,
                esgTagId: esgTagId !== 0 ? esgTagId : null,
            });
            const treeData = recursiveChildrenForEsgScenario({ children: res });
            if (treeData.children.length === 0) {
                notifyError(
                    'Please select an appropriate baseline year, which has data for selected categories!'
                );
            } else {
                await doSwaggerCall(
                    'EsgScenarios',
                    'addEsgScenario',
                    {},
                    {
                        name: values.name,
                        baselineYear: parseInt(values.baseline_year, 10),
                        targetYear: parseInt(values.target_year, 10),
                        targetHierarchy: cutEsgTree(treeData.children, selectedTargetData),
                        siteFilter,
                        esgTagId: parseInt(esgTagId, 10),
                    }
                );
                navigate('/esgscenarios');
                notifySuccess('ESG Scenario added');
            }
        } catch (error) {
            notifyError(error);
        }
    };

    return (
        <PageWithTitle title="Add new ESG Scenario">
            <Formik
                onSubmit={onSubmit}
                initialValues={{ ...defaultScenario }}
                enableReinitialize
                validationSchema={validateSchema}
            >
                {({ isSubmitting, values, setFieldValue, touched, errors }) => (
                    <Form>
                        <Grid item xs={12} sx={{ pt: 2 }}>
                            <Paper sx={{ p: 2 }}>
                                <Grid container alignItems="center">
                                    <BasicScenarioSettings
                                        values={values}
                                        setFieldValue={setFieldValue}
                                        errors={errors}
                                        touched={touched}
                                        availableYears={availableYears}
                                    />
                                    <Grid item xs={12}>
                                        <FilterComponent
                                            filterValue={esgTagId}
                                            setFilterValue={setEsgTagId}
                                            apiEndpoint="EsgTags"
                                            apiFunction="getAllEsgTags"
                                            itemName="esgTags"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Accordion
                                            defaultExpanded
                                            sx={{ border: '1px solid grey' }}
                                        >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel2a-content"
                                                id="panel2a-header"
                                            >
                                                <InputLabel>Target data</InputLabel>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <EsgTargetPicker
                                                    localCompanyYear={values.baseline_year}
                                                    checkedData={selectedTargetData}
                                                    setCheckedData={setSelectedTargetData}
                                                    siteFilter={siteFilter}
                                                    esgTagId={esgTagId}
                                                />
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ScenarioSiteFilter
                                            setSiteFilter={setSiteFilter}
                                            companyId={user.company_id}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <CommonButton
                                            fullWidth
                                            onClick={() => navigate(`/esgscenarios`)}
                                            label="Cancel"
                                            buttonType="secondary"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <CommonButton
                                            fullWidth
                                            disabled={
                                                isSubmitting || selectedTargetData.length === 0
                                            }
                                            type="submit"
                                            label="Save"
                                            buttonType="primary"
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </PageWithTitle>
    );
}

export default AddEsgScenarioPage;
