import numericSort from './numericSort';

export const DATALINE_GROUPS = {
    NONE: 'NONE',
    CATEGORY: 'CATEGORY',
    SITE: 'SITE',
};
export const GROUPBY_OPTIONS = {
    [DATALINE_GROUPS.NONE]: 'No grouping',
    [DATALINE_GROUPS.CATEGORY]: 'By category/source',
    [DATALINE_GROUPS.SITE]: 'By site',
};

export const SCENARIO_GROUPS = {
    NONE: 'NONE',
    TARGET: 'TARGET',
};
export const SCENARIO_GROUPBY_OPTIONS = {
    [SCENARIO_GROUPS.NONE]: 'No grouping',
    [SCENARIO_GROUPS.TARGET]: 'By target',
};

export const SUPPLIER_GROUPS = {
    NONE: 'NONE',
    INDUSTRY: 'INDUSTRY',
    LOCATION: 'LOCATION',
};

export const SUPPLIER_GROUPBY_OPTIONS = {
    [SUPPLIER_GROUPS.NONE]: 'No grouping',
    [SUPPLIER_GROUPS.INDUSTRY]: 'By industry',
    [SUPPLIER_GROUPS.LOCATION]: 'By location',
};

export const ESG_GROUPS = {
    NONE: 'NONE',
    HIERARCHY: 'HIERARCHY',
    SITE: 'SITE',
};
export const ESG_GROUPBY_OPTIONS = {
    [ESG_GROUPS.NONE]: 'No grouping',
    [ESG_GROUPS.HIERARCHY]: 'By hierarchy',
    [ESG_GROUPS.SITE]: 'By site',
};

export function tocToString(toc) {
    return `${toc.map((e) => `${e}`).join('.')}.`;
}

export function transformHierarchyToItem(item, scope, category, subcategory, source, baseline) {
    // should return id, name, section or children
    if (item.children) {
        // has children, not source
        return {
            id: item.id,
            name: item.name,
            type: item.type,
            requirements: item.requirements || [],
            children: numericSort(item.children).map((e) =>
                transformHierarchyToItem(
                    e,
                    scope === null ? item.id : scope,
                    category === null && scope !== null ? item.id : category,
                    subcategory === null && category !== null ? item.id : subcategory,
                    source === null && subcategory !== null ? item.id : source,
                    baseline
                )
            ),
            guide_note: item.guide_note,
        };
    }

    // has no children, should be a source
    return {
        id: item.id,
        name: item.name,
        type: item.type,
        requirements: item.requirements || [],
        section: {
            ...baseline,
            scope: scope === null ? item.id : scope,
            category: category === null ? item.id : category,
            subcategory: subcategory === null ? item.id : subcategory,
            emissionSource: source === null ? item.id : source,
            sectionHeader: item.name,
        },
        guide_note: item.guide_note,
    };
}

// helper function to transform hierarchy to item without system managed emission sources
export function transformHierarchyToItemWithoutSystemManaged(
    item,
    scope,
    category,
    subcategory,
    source,
    baseline
) {
    // should return id, name, section or children
    if (item.children) {
        // filter out system managed emission sources from transformResult
        if (item.type === 'subcategory') {
            return {
                ...item,
                children: item.children.filter((child) => child.system_managed === 0),
            };
        }

        // has children, not source
        return {
            id: item.id,
            name: item.name,
            type: item.type,
            requirements: item.requirements || [],
            children: numericSort(item.children).map((e) =>
                transformHierarchyToItemWithoutSystemManaged(
                    e,
                    scope === null ? item.id : scope,
                    category === null && scope !== null ? item.id : category,
                    subcategory === null && category !== null ? item.id : subcategory,
                    source === null && subcategory !== null ? item.id : source,
                    baseline
                )
            ),
            guide_note: item.guide_note,
        };
    }

    // has no children, should be a source
    return item.system_managed === true
        ? null
        : {
              id: item.id,
              name: item.name,
              type: item.type,
              requirements: item.requirements || [],
              section: {
                  ...baseline,
                  scope: scope === null ? item.id : scope,
                  category: category === null ? item.id : category,
                  subcategory: subcategory === null ? item.id : subcategory,
                  emissionSource: source === null ? item.id : source,
                  sectionHeader: item.name,
              },
              guide_note: item.guide_note,
              system_managed: item.system_managed,
          };
}

export function transformEsgHierarchyToItem(
    item,
    esgCategory,
    esgSubcategory,
    esgDisclosure,
    esgQuestion,
    baseline
) {
    // should return id, name, section or children
    if (item.children) {
        // has children, not question
        return {
            id: item.id,
            name: item.name,
            type: item.type,
            children: numericSort(item.children).map((e) =>
                transformEsgHierarchyToItem(
                    e,
                    esgCategory === null ? item.id : esgCategory,
                    esgSubcategory === null && esgCategory !== null ? item.id : esgSubcategory,
                    esgDisclosure === null && esgSubcategory !== null ? item.id : esgDisclosure,
                    esgQuestion === null && esgDisclosure !== null ? item.id : esgQuestion,
                    baseline
                )
            ),
            guide_note: item.guide_note,
        };
    }

    // has no children, should be a esgQuestion
    return {
        id: item.id,
        name: item.name,
        type: item.type,
        section: {
            ...baseline,
            esgCategory: esgCategory === null ? item.id : esgCategory,
            esgSubcategory: esgSubcategory === null ? item.id : esgSubcategory,
            esgDisclosure: esgDisclosure === null ? item.id : esgDisclosure,
            esgQuestion: esgQuestion === null ? item.id : esgQuestion,
            sectionHeader: item.name,
            fields: item?.fields,
        },
        guide_note: item.guide_note,
    };
}

export function groupCategory(item) {
    return `${item?.scope_name}`;
}

export function groupSubcategory(item) {
    return `${item?.scope_name} - ${item?.category_name}`;
}

export function groupEmissionSource(item) {
    return `${item?.scope_name} - ${item?.category_name} - ${item?.subcategory_name}`;
}

export function groupQuestion(item) {
    return `${item?.category_name} - ${item?.subcategory_name} - ${item?.disclosure_name}`;
}

export function groupEsgSubcategory(item) {
    return `${item?.category_name}`;
}

export function groupDisclosure(item) {
    return `${item?.category_name} - ${item?.subcategory_name}`;
}
