import React, { useEffect, useState } from 'react';
import { Grid, TextField, Paper, InputAdornment } from '@mui/material';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { doSwaggerCall } from '../../../hooks/useApi';
import useNotify from '../../../hooks/useNotify';
import { useAuth } from '../../../hooks/useAuth';
import ReductionChart from '../components/ReductionChart';
import recursiveSumChildrenForScenario from '../helpers/recursiveSumChildrenForScenario';
import filterTargetScopes from '../helpers/filterTargetScopes';
import TargetPicker from '../components/targetpicker/components/TargetPicker';
import {
    filterTargetData,
    filterTargetDataToScope,
    treeCut,
} from '../helpers/targetPickerHelperFunctions';
import { useGlobalFilters } from '../../../hooks/useGlobalFilters';
import PageWithTitle from '../../../components/atom/PageWithTitle';
import CommonButton from '../../../components/atom/CommonButton';
import FilterComponent from '../../../components/atom/FilterComponent';
import ScenarioSiteFilter from '../components/targetpicker/components/ScenarioSiteFilter';
import BasicScenarioSettings from '../components/BasicScenarioSettings';

function GrabAndCopyValue({
    scenarioName,
    baselineYear,
    targetYear,
    reduction,
    setScenarioName,
    setBaselineYear,
    setTargetYear,
    setReduction,
}) {
    useEffect(() => {
        setScenarioName(scenarioName);
        setBaselineYear(parseInt(baselineYear, 10));
        setTargetYear(parseInt(targetYear, 10));
        setReduction(parseInt(reduction, 10));
    }, [
        setScenarioName,
        setBaselineYear,
        setTargetYear,
        setReduction,
        scenarioName,
        baselineYear,
        targetYear,
        reduction,
    ]);
    return null;
}

const validateSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    baseline_year: Yup.number().min(2000).required('Required'),
    target_reduction: Yup.number()
        .min(0, 'Target reduction goal must be greater than 0')
        .max(100, 'Target reduction goal must be lesser than 100')
        .required('Required'),
    target_year: Yup.number()
        .min(2000)
        .required('Required')
        .moreThan(Yup.ref('baseline_year'), 'Target year is not greater than baseline year!'),
});

function AddScenarioPage() {
    const { user } = useAuth();
    const { availableYears } = useGlobalFilters();
    const navigate = useNavigate();
    const { notifyError, notifySuccess } = useNotify();
    const [selectedTargetData, setSelectedTargetData] = useState([]);
    const [reduction, setReduction] = useState(50);
    const [scenarioName, setScenarioName] = useState();
    const [baselineYear, setBaselineYear] = useState(
        availableYears.filter((e) => e.state === 'closed')[0]?.year || new Date().getFullYear() - 1
    );
    const [targetYear, setTargetYear] = useState(new Date().getFullYear() + 9);
    const [siteFilter, setSiteFilter] = useState([]);
    const [datalineTagId, setDatalineTagId] = useState(0);

    const defaultScenario = {
        name: 'New Scenario',
        company_id: user.company_id,
        baseline_year:
            availableYears.filter((e) => e.state === 'closed')[0]?.year ||
            new Date().getFullYear() - 1,
        target_year: new Date().getFullYear() + 9,
        target_reduction: 50,
        target_data: [],
        dataline_tag_id: 0,
        site_filter: [],
    };

    const onSubmit = async (values) => {
        try {
            // checkbox array validation
            if (selectedTargetData.length === 0) {
                notifyError('You should pick at least one target data!');
                return;
            }
            const res = await doSwaggerCall('Hierarchy', 'getSourceHierarchySum', {
                year: values.baseline_year,
                reportByTag: parseInt(datalineTagId, 10),
                siteIdList: siteFilter,
            });
            // extend the tree with baseline sum and optimal target sum
            const treeWithSum = recursiveSumChildrenForScenario(
                { children: res },
                parseInt(reduction, 10)
            );
            if (treeWithSum.children.length === 0) {
                notifyError(
                    'Please select an appropriate baseline year, which has data for selected scope(s)!'
                );
            } else {
                await doSwaggerCall(
                    'Scenarios',
                    'addScenario',
                    {},
                    {
                        name: values.name,
                        baselineYear: parseInt(values.baseline_year, 10),
                        targetYear: parseInt(values.target_year, 10),
                        targetReduction: parseInt(values.target_reduction, 10),
                        // we store the ids of the scopes
                        targetScopes: filterTargetData(selectedTargetData, 'scope'),
                        // we store the ids of the categories
                        targetCategories: filterTargetData(selectedTargetData, 'category'),
                        targetScopesHierarchy: filterTargetScopes(
                            filterTargetDataToScope(selectedTargetData),
                            treeCut(treeWithSum.children, selectedTargetData)
                        ),
                        datalineTagId: parseInt(datalineTagId, 10),
                        siteFilter,
                    }
                );
                navigate('/scenarios');
                notifySuccess('Scenario added');
            }
        } catch (error) {
            notifyError(error);
        }
    };

    return (
        <PageWithTitle title="Add new scenario">
            <Formik
                onSubmit={onSubmit}
                initialValues={{ ...defaultScenario }}
                enableReinitialize
                validationSchema={validateSchema}
            >
                {({ isSubmitting, values, setFieldValue, touched, errors }) => (
                    <Form>
                        <GrabAndCopyValue
                            scenarioName={values?.name}
                            baselineYear={values?.baseline_year}
                            targetYear={values?.target_year}
                            reduction={values?.target_reduction}
                            datalineTagId={values?.dataline_tag_id}
                            siteFilter={values?.site_filter}
                            setScenarioName={setScenarioName}
                            setBaselineYear={setBaselineYear}
                            setTargetYear={setTargetYear}
                            setReduction={setReduction}
                            setDatalineTagId={setDatalineTagId}
                            setSiteFilter={setSiteFilter}
                        />
                        <Grid item xs={12} sx={{ pt: 2 }}>
                            <Paper sx={{ p: 2 }}>
                                <Grid container alignItems="center">
                                    <BasicScenarioSettings
                                        values={values}
                                        setFieldValue={setFieldValue}
                                        errors={errors}
                                        touched={touched}
                                        availableYears={availableYears}
                                    />
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            autoComplete="off"
                                            label="Target reduction goal (%)"
                                            value={values.target_reduction}
                                            onChange={(ev) =>
                                                setFieldValue('target_reduction', ev.target.value)
                                            }
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        %
                                                    </InputAdornment>
                                                ),
                                            }}
                                            error={
                                                touched.target_reduction &&
                                                Boolean(errors.target_reduction)
                                            }
                                            helperText={
                                                touched.target_reduction
                                                    ? errors.target_reduction
                                                    : ''
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FilterComponent
                                            filterValue={datalineTagId}
                                            setFilterValue={setDatalineTagId}
                                            apiEndpoint="DatalineTags"
                                            apiFunction="getAllDatalineTags"
                                            itemName="datalineTags"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ScenarioSiteFilter
                                            setSiteFilter={setSiteFilter}
                                            companyId={user.company_id}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TargetPicker
                                            year={values.baseline_year}
                                            checkedData={selectedTargetData}
                                            setCheckedData={setSelectedTargetData}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <CommonButton
                                            fullWidth
                                            onClick={() => navigate(`/scenarios`)}
                                            label="Cancel"
                                            buttonType="secondary"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <CommonButton
                                            fullWidth
                                            disabled={
                                                isSubmitting || selectedTargetData.length === 0
                                            }
                                            type="submit"
                                            label="Save"
                                            buttonType="primary"
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Form>
                )}
            </Formik>
            <Grid
                container
                xs={12}
                justifyContent="space-between"
                spacing={0}
                sx={{ pb: 2, pt: 2 }}
            >
                <ReductionChart
                    scenarioName={scenarioName}
                    baselineYear={baselineYear}
                    targetYear={targetYear}
                    reduction={reduction}
                    datalineTagId={datalineTagId}
                    siteFilter={siteFilter}
                    categoryIdList={selectedTargetData
                        .filter((e) => e.type === 'category')
                        .map((e) => e.id)}
                    selectedScopes={filterTargetDataToScope(selectedTargetData)}
                    isMultiple={false}
                />
            </Grid>
        </PageWithTitle>
    );
}

export default AddScenarioPage;
