/**
 * Helper recursive function to convert esg scenario data to hierarchy data
 *
 * @param item esg hierarchy
 */
const recursiveChildrenForEsgScenario = (item) => {
    // don't have children
    if (!Array.isArray(item.children)) {
        return {
            id: item.id,
            name: item.name,
            type: item.type,
            values: item.values,
            site_name: item.Site?.name || 'Corporate',
        };
    }

    const newChildren = item.children.map((e) => recursiveChildrenForEsgScenario(e));
    return {
        id: item.id,
        name: item.name,
        type: item.type,
        children: newChildren,
    };
};

export default recursiveChildrenForEsgScenario;
