// Helper function to resolve notification types
function notificationTypeMapping(notificationType, itemName) {
    let resultNotification = '';
    switch (notificationType) {
        case 'dataline_assigned':
            resultNotification = `You have a new dataline assigned to you: ${itemName}`;
            break;
        case 'dataline_owned':
            resultNotification = `You have a new dataline owned by you: ${itemName}`;
            break;
        case 'dataline_assigned_remove':
            resultNotification = `You have been removed from dataline (assign): ${itemName}`;
            break;
        case 'dataline_owned_remove':
            resultNotification = `You have been removed from dataline (owned): ${itemName}`;
            break;
        case 'dataline_edited':
            resultNotification = `A dataline has been edited that is owned by you: ${itemName}`;
            break;
        case 'dataline_deleted':
            resultNotification = `A dataline has been deleted that is owned by you: ${itemName}`;
            break;
        case 'esg_assigned':
            resultNotification = `You have a new ESG assigned to you: ${itemName}`;
            break;
        case 'esg_owned':
            resultNotification = `You have a new ESG owned by you: ${itemName}`;
            break;
        case 'esg_assigned_remove':
            resultNotification = `You have been removed from ESG (assign): ${itemName}`;
            break;
        case 'esg_owned_remove':
            resultNotification = `You have been removed from ESG (owned): ${itemName}`;
            break;
        case 'esg_edited':
            resultNotification = `A ESG has been edited that is owned by you: ${itemName}`;
            break;
        case 'esg_deleted':
            resultNotification = `A ESG has been deleted that is owned by you: ${itemName}`;
            break;
        default:
            break;
    }
    return resultNotification;
}
module.exports.notificationTypeMapping = notificationTypeMapping;
