import { CircularProgress, Grid, Paper } from '@mui/material';
import React, { useState, useCallback, useEffect } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeView from '@mui/lab/TreeView';
import useNotify from '../../../hooks/useNotify';
import { doSwaggerCall } from '../../../hooks/useApi';
import { transformEsgHierarchyToItem } from '../../../helpers/groups';
import getCheckedChildrenNumberRecursive from '../../companies/components/helpers/getCheckedChildrenNumberRecursive';
import EsgCategoryLevel from './EsgCategoryLevel';
import recursiveTreeFilter from '../../../helpers/recursiveTreeFilter';
import SearchInput from '../../../components/atom/SearchInput';

const EsgTargetPicker = ({
    localCompanyYear,
    checkedData,
    setCheckedData,
    siteFilter,
    esgTagId,
}) => {
    const [dataGroups, setDataGroups] = useState([]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const { notifyError } = useNotify();

    const loadHierarchy = useCallback((baselineYear, siteIdList, tagId) => {
        setLoading(true);
        doSwaggerCall('EsgQuestions', 'getEsgQuestionHierarchy', {
            year: baselineYear,
            hasanyesgs: true, // show only data that have esgs
            siteIdList,
            esgTagId: tagId !== 0 ? tagId : null,
        })
            .then((hierarchy) => {
                const transformResult = hierarchy.map((e) => transformEsgHierarchyToItem(e));
                setDataGroups(transformResult);
                setLoading(false);
            })
            .catch((error) => {
                notifyError(!baselineYear ? 'Baseline year is required' : error);
            });
    }, []);

    useEffect(() => {
        loadHierarchy(localCompanyYear, siteFilter, esgTagId);
    }, [localCompanyYear, siteFilter, esgTagId]);

    // search all hierarchy
    useEffect(() => {
        if (search === '') {
            setFilteredData(dataGroups);
            return;
        }
        setFilteredData(recursiveTreeFilter(dataGroups, search.toLowerCase()));
    }, [search, dataGroups]);

    const getCheckedChildrenNumber = useCallback(
        (item) => {
            return getCheckedChildrenNumberRecursive(checkedData, item);
        },
        [checkedData]
    );

    return (
        <Grid item xs={12} sx={{ pt: 2 }}>
            <Paper sx={{ p: 2, width: '100%' }}>
                <Grid container>
                    {loading && (
                        <Grid item xs={12}>
                            <CircularProgress size="1.5rem" />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <SearchInput fullWidth search={search} setSearch={setSearch} />
                    </Grid>
                    {filteredData.map((esgCategory) => (
                        <Grid item xs={12} key={`${esgCategory.id} - ${esgCategory.name}`}>
                            <TreeView
                                key={`${esgCategory.id} - ${esgCategory.name}`}
                                aria-label="icon expansion"
                                defaultCollapseIcon={<ExpandMoreIcon />}
                                defaultExpandIcon={<ChevronRightIcon />}
                                defaultExpanded={[]}
                            >
                                <EsgCategoryLevel
                                    key={`${esgCategory.id} - ${esgCategory.name}`}
                                    checkedData={checkedData}
                                    esg_category={esgCategory}
                                    setCheckedData={setCheckedData}
                                    getCheckedChildrenNumber={getCheckedChildrenNumber}
                                />
                            </TreeView>
                        </Grid>
                    ))}
                </Grid>
            </Paper>
        </Grid>
    );
};

export default EsgTargetPicker;
