import {
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import React, { useState } from 'react';
import BlockWithTitle from '../../../components/atom/BlockWithTitle';
import AutocompleteSelect from '../../../components/atom/AutoCompleteSelect';
import FieldEditor from '../../../components/fieldeditor/FieldEditor';
import UploadImageInput from '../../../components/atom/UploadImageInput';
import {
    companyTagAutocomplete,
    esgFrameworkAutocomplete,
    locationAutocomplete,
} from '../../../autocomplete';
import { FORMULA_OPTIONS } from '../../../helpers/defaultFieldHelpers';

const BasicSettings = ({ values, setFieldValue, touched, errors, calendarYear, isSuperAdmin }) => {
    const [showEsgFrameworks, setShowEsgFrameworks] = useState(
        values.allowed_esg_frameworks !== null
    );

    return (
        <>
            <Grid item xs={8}>
                <BlockWithTitle title="Company settings">
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            autoComplete="off"
                            label="Name"
                            value={values.name}
                            onChange={(ev) => {
                                setFieldValue('name', ev.target.value);
                            }}
                            error={touched.name && Boolean(errors.name)}
                            helperText={touched.name ? errors.name : ''}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <AutocompleteSelect
                            initCall={locationAutocomplete.init}
                            autocompleteCall={locationAutocomplete.search}
                            value={values?.location_id}
                            setValue={(ev) => setFieldValue('location_id', ev)}
                        >
                            <TextField label="Location" required />
                        </AutocompleteSelect>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel>CO2e default formula</InputLabel>
                            <Select
                                value={values.co2e_logic}
                                label="CO2e default formula"
                                onChange={(ev) => setFieldValue('co2e_logic', ev.target.value)}
                            >
                                {FORMULA_OPTIONS.map((formula) => (
                                    <MenuItem key={formula} value={formula}>
                                        {formula}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel>Fiscal year starts at</InputLabel>
                        <TextField
                            fullWidth
                            type="date"
                            id="fiscalYear"
                            name="fiscalYear"
                            min={`${calendarYear}-01-01`}
                            max={`${calendarYear}-12-31`}
                            value={values.fiscal_year_starts_at}
                            onChange={(ev) =>
                                setFieldValue('fiscal_year_starts_at', ev.target.value)
                            }
                        />
                    </Grid>
                    {isSuperAdmin && (
                        <Grid item xs={12}>
                            <AutocompleteSelect
                                initCall={companyTagAutocomplete.init}
                                autocompleteCall={companyTagAutocomplete.search}
                                value={values.company_tags}
                                multiple
                                setValue={(ev) => {
                                    setFieldValue('company_tags', ev);
                                }}
                            >
                                <TextField label="Company tags" />
                            </AutocompleteSelect>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            autoComplete="off"
                            label="Flag limit (%)"
                            value={values.flag_limit}
                            onChange={(ev) => setFieldValue('flag_limit', ev.target.value)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                            error={touched.flag_limit && Boolean(errors.flag_limit)}
                            helperText={touched.flag_limit ? errors.flag_limit : ''}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel>Two factor authentication setting</InputLabel>
                            <Select
                                value={values.two_factor_auth_setting}
                                label="Two factor authentication setting"
                                onChange={(ev) =>
                                    setFieldValue('two_factor_auth_setting', ev.target.value)
                                }
                            >
                                <MenuItem value="required">Required</MenuItem>
                                <MenuItem value="optional">Optional</MenuItem>
                                <MenuItem value="not_available">Not available</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel>Notify users on empty datalines and ESGs</InputLabel>
                            <Select
                                value={values.notification_cadence}
                                label="Notify users on empty datalines and ESGs"
                                onChange={(ev) =>
                                    setFieldValue('notification_cadence', ev.target.value)
                                }
                            >
                                <MenuItem value="0">Don&apos;t notify</MenuItem>
                                <MenuItem value="7">Weekly</MenuItem>
                                <MenuItem value="14">Bi-weekly</MenuItem>
                                <MenuItem value="30">Monthly</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid
                        key="esg_frameworks"
                        item
                        xs={4}
                        sx={{
                            mt: 1,
                            alignItems: 'center',
                            display: 'flex',
                        }}
                    >
                        <FormControlLabel
                            label="Allow all ESG frameworks"
                            control={
                                <Checkbox
                                    checked={
                                        values.allowed_esg_frameworks === null && !showEsgFrameworks
                                    }
                                    onChange={(ev) => {
                                        if (ev.target.checked) {
                                            setFieldValue('allowed_esg_frameworks', null);
                                        } else {
                                            setFieldValue('allowed_esg_frameworks', []);
                                        }
                                        setShowEsgFrameworks(!ev.target.checked);
                                    }}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <AutocompleteSelect
                            disabled={!showEsgFrameworks}
                            initCall={esgFrameworkAutocomplete.init}
                            autocompleteCall={esgFrameworkAutocomplete.search}
                            value={values?.allowed_esg_frameworks || []}
                            multiple
                            setValue={(ev) => {
                                setFieldValue('allowed_esg_frameworks', ev);
                            }}
                        >
                            <TextField label="Only allow these frameworks" />
                        </AutocompleteSelect>
                    </Grid>
                </BlockWithTitle>
            </Grid>
            <Grid item xs={8}>
                <BlockWithTitle title="Field defaults">
                    <Grid item xs={12}>
                        <FieldEditor
                            values={values}
                            setFieldValue={setFieldValue}
                            isSuperAdmin={isSuperAdmin}
                        />
                    </Grid>
                </BlockWithTitle>
            </Grid>
            <Grid item xs={8}>
                <BlockWithTitle title="Company logo">
                    <Grid item xs={12}>
                        <Typography variant="caption">
                            When uploading your logo, make sure its dimensions are around 900x300
                            pixels for an optimal display on our website. To achieve the best visual
                            result, we recommend using a transparent PNG format.
                        </Typography>
                    </Grid>
                    <UploadImageInput
                        value={values?.logo}
                        setFieldValue={setFieldValue}
                        picture="logo"
                    />
                </BlockWithTitle>
            </Grid>
        </>
    );
};

export default BasicSettings;
