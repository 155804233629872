import {
    CircularProgress,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from 'react-router-dom';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SkeletonTable from '../../../components/atom/SkeletonTable';
import useNotify from '../../../hooks/useNotify';
import { MODAL_TYPE, useModals } from '../../../hooks/useModal';
import { doSwaggerCall, doSwaggerCallForPdf } from '../../../hooks/useApi';
import SortableTableCell from '../../../components/atom/SortableTableCell';
import { TABLE_ROW_COUNT_OPTIONS } from '../../../helpers/constans';
import { handleChangeRowsPerPage, handlePageChange } from '../../../helpers/handleTableFunctions';
import { BACKEND_URL } from '../../../config';

const EsgScenariosTable = ({
    rows,
    limit,
    page,
    setParams,
    total,
    loading,
    reloadData,
    sort,
    sortDir,
    setSorting,
}) => {
    const { showModal } = useModals();
    const { notifySuccess, notifyError } = useNotify();
    const [generatingPdf, setGeneratingPdf] = useState(false);
    const [generatingId, setGeneratingId] = useState(null);

    const deleteScenario = useCallback((id) => {
        doSwaggerCall('EsgScenarios', 'deleteEsgScenario', { id })
            .then(() => {
                notifySuccess('ESG Scenario deleted');
                reloadData();
            })
            .catch(() => {
                notifyError('Cannot delete ESG scenario');
            });
    }, []);

    const scenarioExport = async (id) => {
        try {
            const reportRequest = await doSwaggerCall('Reports', 'scenarioReportRequest', {
                scenarioId: id,
                documentType: 'esg',
            });
            window.open(
                `${BACKEND_URL}/1.0/reports/esgscenariodownload?token=${reportRequest.token}`,
                '_blank',
                'noopener,noreferrer'
            );
        } catch (err) {
            notifyError(err);
        }
    };

    const pdfExport = async (id) => {
        setGeneratingPdf(true);
        setGeneratingId(id);
        let reportRequest;
        try {
            reportRequest = await doSwaggerCallForPdf('Reports', 'esgScenarioReportRequestPdf', {
                scenarioId: id,
            });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(reportRequest);
            link.download = `esg_scenario_${id}.pdf`;
            link.click();
        } catch (err) {
            notifyError(err);
            setGeneratingId(null);
            setGeneratingPdf(false);
        } finally {
            setGeneratingId(null);
            setGeneratingPdf(false);
        }
    };

    // different confirm function for report by reportType
    const confirmFunction = (reportType, scenarioId) => {
        switch (reportType) {
            case 'PDF':
                return pdfExport(scenarioId).catch((err) => {
                    notifyError(err);
                });
            case 'HTML':
                return scenarioExport(scenarioId).catch((err) => {
                    notifyError(err);
                });
            default:
                return () => {
                    notifyError('Something went wrong');
                };
        }
    };

    return (
        <Grid item xs={12}>
            <TableContainer component={Paper}>
                <Table aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>
                            <SortableTableCell
                                arrow={sort === 'name' ? sortDir : ''}
                                onClick={() => setSorting('name')}
                            >
                                Name
                            </SortableTableCell>
                            <SortableTableCell
                                arrow={sort === 'target_year' ? sortDir : ''}
                                onClick={() => setSorting('target_year')}
                            >
                                Target year
                            </SortableTableCell>
                            <SortableTableCell
                                arrow={sort === 'baseline_year' ? sortDir : ''}
                                onClick={() => setSorting('baseline_year')}
                            >
                                Baseline year
                            </SortableTableCell>
                            <TableCell>Site names</TableCell>
                            <TableCell>ESG tag</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <SkeletonTable rows={10} cells={6} />
                        ) : (
                            rows?.map((row) => {
                                return (
                                    <TableRow key={row.id}>
                                        <TableCell>{row.name}</TableCell>
                                        <TableCell>{row.target_year}</TableCell>
                                        <TableCell>{row.baseline_year}</TableCell>
                                        <TableCell>{row.site_names.join(', ')}</TableCell>
                                        <TableCell>{row.esg_tag_name}</TableCell>
                                        <TableCell align="right" width="20%">
                                            <Tooltip title="Copy ESG Scenario">
                                                <IconButton
                                                    onClick={() => {
                                                        showModal(MODAL_TYPE.COPY_SCENARIO, {
                                                            title: `Copy and add as a new ESG Scenario`,
                                                            row,
                                                            reloadData,
                                                            scenarioType: 'esg',
                                                        });
                                                    }}
                                                >
                                                    <ContentCopyIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Download report">
                                                <IconButton
                                                    onClick={() => {
                                                        showModal(MODAL_TYPE.SELECT_REPORT, {
                                                            isScenario: true,
                                                            confirm: (reportType) => {
                                                                confirmFunction(
                                                                    reportType,
                                                                    row.id
                                                                ).catch((err) => {
                                                                    notifyError(err);
                                                                });
                                                            },
                                                        });
                                                    }}
                                                    sx={{ mr: 0.625 }}
                                                    disabled={generatingPdf}
                                                >
                                                    {generatingPdf && generatingId === row.id ? (
                                                        <CircularProgress
                                                            color="inherit"
                                                            size={20}
                                                        />
                                                    ) : (
                                                        <FeedOutlinedIcon />
                                                    )}
                                                </IconButton>
                                            </Tooltip>
                                            <Link to={`/esgscenarios/edit/${row.id}`}>
                                                <Tooltip title="Edit">
                                                    <IconButton>
                                                        <EditIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Link>
                                            <IconButton
                                                onClick={() => {
                                                    showModal(MODAL_TYPE.CONFIRMATION, {
                                                        title: `Are you sure you want to remove ESG scenario?`,
                                                        content: 'This action is irreversible.',
                                                        confirm: () => deleteScenario(row.id),
                                                    });
                                                }}
                                            >
                                                <Tooltip title="Delete">
                                                    <DeleteIcon />
                                                </Tooltip>
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                count={total || 0}
                rowsPerPage={limit}
                component="div"
                page={page}
                onPageChange={(_, newPage) => handlePageChange(newPage, setParams, limit)}
                rowsPerPageOptions={TABLE_ROW_COUNT_OPTIONS}
                onRowsPerPageChange={(event) => handleChangeRowsPerPage(event, setParams)}
            />
        </Grid>
    );
};

export default EsgScenariosTable;
