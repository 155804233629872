import React, { useCallback, useContext, useEffect, useState } from 'react';
import { LOGGED_IN_USER_KEY } from '../config';

const GlobalFilterContext = React.createContext();

const getAvailableYearsFromStorage = () => {
    try {
        const user = JSON.parse(localStorage.getItem(LOGGED_IN_USER_KEY));
        if (user === null) {
            return [];
        }
        if (!user.availableYears) {
            // should log out user
            localStorage.removeItem(LOGGED_IN_USER_KEY);
            window.location.reload();
            return [];
        }
        return user.availableYears;
    } catch (err) {
        console.log(err);
        return [];
    }
};

export function GlobalFilterContextProvider({ children }) {
    const [availableYears, setAvailableYears] = useState(() => getAvailableYearsFromStorage());
    const [year, setYear] = useState(
        parseInt(localStorage.getItem('year'), 10) || new Date().getFullYear() - 1
    );
    const [closedYear, setClosedYear] = useState(false);

    const [scope, setScope] = useState(null);
    const [scopeName, setScopeName] = useState(null);
    const [category, setCategory] = useState(null);
    const [categoryName, setCategoryName] = useState(null);
    const [subcategory, setSubcategory] = useState(null);
    const [subcategoryName, setSubcategoryName] = useState(null);
    const [emissionSource, setEmissionSource] = useState(null);
    const [emissionSourceName, setEmissionSourceName] = useState(null);
    const [edited, setEdited] = useState(false);
    const [site, setSite] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]); // string
    const [selectedSites, setSelectedSites] = useState([]); // number

    const [siteNames, setSiteNames] = useState([]);

    const setSourceFilters = useCallback(
        ({
            scopeFilter,
            scopeNameFilter,
            categoryFilter,
            categoryNameFilter,
            subcategoryFilter,
            subcategoryNameFilter,
            emissionSourceFilter,
            emissionSourceNameFilter,
        }) => {
            if (typeof scopeFilter !== 'undefined') {
                setScope(scopeFilter);
                setScopeName(scopeNameFilter);
            }
            if (typeof categoryFilter !== 'undefined') {
                setCategory(categoryFilter);
                setCategoryName(categoryNameFilter);
            }
            if (typeof subcategoryFilter !== 'undefined') {
                setSubcategory(subcategoryFilter);
                setSubcategoryName(subcategoryNameFilter);
            }
            if (typeof emissionSourceFilter !== 'undefined') {
                setEmissionSource(emissionSourceFilter);
                setEmissionSourceName(emissionSourceNameFilter);
            }
        },
        [setScope, setCategory, setSubcategory, setEmissionSource]
    );

    const setSiteFilters = useCallback(
        ({ siteFilter, siteNamesFilter }) => {
            if (typeof siteFilter !== 'undefined') {
                setSite((oldSite) => {
                    return oldSite.join(',') === siteFilter.join(',') ? oldSite : siteFilter;
                });
                setSiteNames((oldSiteNames) => {
                    return oldSiteNames.join(',') === siteNamesFilter.join(',')
                        ? oldSiteNames
                        : siteNamesFilter;
                });
            }
        },
        [setSite, setSiteNames]
    );

    useEffect(() => {
        localStorage.setItem('year', year);
        setClosedYear(availableYears.find((e) => e.year === year)?.state === 'closed');
    }, [year, availableYears]);

    return (
        <GlobalFilterContext.Provider
            value={{
                scope,
                scopeName,
                category,
                categoryName,
                subcategory,
                subcategoryName,
                emissionSource,
                emissionSourceName,
                availableYears,
                setAvailableYears,
                year,
                setYear,
                site,
                setSite,
                siteNames,
                setSourceFilters,
                setSiteFilters,
                selectedSites,
                setSelectedSites,
                selectedTags,
                setSelectedTags,
                edited,
                setEdited,
                closedYear,
            }}
        >
            {children}
        </GlobalFilterContext.Provider>
    );
}

GlobalFilterContext.displayName = 'GlobalFilterContext';

/**
 * Global filters
 * @returns {{scope, scopeName, category, categoryName, subcategory, subcategoryName, emissionSource, emissionSourceName, availableYears, setAvailableYears: setAvailableYears, year, setYear: setYear, site, setSite: setSite, siteNames, setSourceFilters: setSourceFilters, setSiteFilters: setSiteFilters, selectedSites, setSelectedSites: setSelectedSites, selectedTags, setSelectedTags: setSelectedTags, siteNames, edited, setEdited, closedYear}|unknown}
 */
export function useGlobalFilters() {
    return useContext(GlobalFilterContext);
}
