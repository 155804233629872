import { Form, Formik } from 'formik';
import {
    Checkbox,
    FormControlLabel,
    Typography,
    Grid,
    TextField,
    InputLabel,
    InputAdornment,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import AddItemButton from '../../../components/atom/AddItemButton';
import AutocompleteSelect from '../../../components/atom/AutoCompleteSelect';
import { userAutocomplete, siteAutocomplete } from '../../../autocomplete';
import DatalineFormulaPickerDropdown from './DatalineFormulaPickerDropdown';
import LastModified from './LastModified';
import FreeSoloDatalineTagAutoComplete from '../../../components/atom/FreeSoloDatalineTagAutoComplete';
import FloatInput from '../../../components/atom/FloatInput';
import { useAuth } from '../../../hooks/useAuth';
import { PERMISSIONS } from '../../../helpers/constans';
import DocumentField from './DocumentField';
import HelperIcon from '../../../components/atom/HelperIcon';
import validateDataline from '../helpers/validateDataline';
import CommonButton from '../../../components/atom/CommonButton';

function DatalineForm({
    onSubmit,
    dataline,
    newDataline,
    setEdited,
    percentages,
    setPercentages,
    closedYear,
    isAirportCalculatorVisible,
}) {
    const [showNotes, setShowNotes] = useState(!(dataline.notes === null || dataline.notes === ''));
    const [showDocuments, setShowDocuments] = useState(dataline?.documents?.length > 0);
    const [showAssign, setShowAssign] = useState(!!dataline?.assigned_user?.user_id);
    const [showOwner, setShowOwner] = useState(!!dataline?.owner_user?.user_id);
    const [showDatalineTags, setShowDatalineTags] = useState(dataline?.datalineTags.length > 0);
    const [defaultField, setDefaultField] = useState(null);
    const [showDate, setShowDate] = useState(!!dataline?.to_date || !!dataline?.from_date);
    const [showCost, setShowCost] = useState(!!dataline?.cost);
    const [disabledByAirportCalculator, setDisabledByAirportCalculator] = useState(false);

    const { userPermissions } = useAuth();
    const basicModifyDatalinePermission = userPermissions.includes(
        PERMISSIONS.BASIC_MODIFY_DATALINES
    );
    const complexModifyDatalinePermission = userPermissions.includes(
        PERMISSIONS.COMPLEX_MODIFY_DATALINES
    );
    const systemManagedDataline = dataline?.system_managed;
    const systemManagedEmissionSource = dataline?.system_managed_emission_source;

    // set site default_field
    useEffect(() => {
        setDefaultField(dataline?.site_default_field);
    }, [dataline]);

    const validate = (values) => {
        return validateDataline(values);
    };

    return (
        <Formik onSubmit={onSubmit} enableReinitialize initialValues={dataline} validate={validate}>
            {({ isSubmitting, values, setFieldValue, touched, errors }) => (
                <Form>
                    <Grid container spacing={2}>
                        <Grid item container spacing={2}>
                            <Grid item container justifyContent="space-between">
                                <Grid item>
                                    {newDataline && (
                                        <Grid item sx={{ pb: 1 }}>
                                            <Typography variant="h4" style={{ fontSize: '14px' }}>
                                                Add new Dataline
                                            </Typography>
                                        </Grid>
                                    )}
                                    <Grid item>
                                        <Typography variant="h6">
                                            {values.emission_source.name}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                {dataline.emission_source.guide_note && (
                                    <Grid item>
                                        <HelperIcon
                                            title={dataline.emission_source.name}
                                            guideNote={dataline.emission_source.guide_note}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    multiline
                                    autoComplete="off"
                                    label="Emission description"
                                    value={values.emission_description}
                                    onChange={(ev) =>
                                        setFieldValue('emission_description', ev.target.value)
                                    }
                                    InputProps={{
                                        startAdornment: values.incorrect === true && (
                                            <WarningIcon
                                                sx={{ mr: 0.5 }}
                                                style={{ color: 'red' }}
                                            />
                                        ),
                                    }}
                                    disabled={
                                        (!basicModifyDatalinePermission &&
                                            !complexModifyDatalinePermission) ||
                                        systemManagedDataline ||
                                        systemManagedEmissionSource ||
                                        closedYear
                                    }
                                />
                            </Grid>
                        </Grid>
                        <Grid item container spacing={2}>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={!!values.is_estimated}
                                            onChange={(e) =>
                                                setFieldValue('is_estimated', !!e.target.checked)
                                            }
                                        />
                                    }
                                    label="Estimated value"
                                    labelPlacement="end"
                                    disabled={
                                        (!basicModifyDatalinePermission &&
                                            !complexModifyDatalinePermission) ||
                                        systemManagedDataline ||
                                        systemManagedEmissionSource ||
                                        closedYear
                                    }
                                />
                            </Grid>
                            <Grid item style={{ flex: '1 1 0' }}>
                                <AutocompleteSelect
                                    size="small"
                                    initCall={siteAutocomplete.init}
                                    autocompleteCall={siteAutocomplete.search}
                                    value={values?.site_id}
                                    setValue={(ev, getItem) => {
                                        setDefaultField(getItem(ev));
                                        setFieldValue('site_id', ev);
                                    }}
                                    disabled={
                                        (!basicModifyDatalinePermission &&
                                            !complexModifyDatalinePermission) ||
                                        systemManagedDataline ||
                                        systemManagedEmissionSource ||
                                        closedYear
                                    }
                                >
                                    <TextField label="Site" required />
                                </AutocompleteSelect>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <DatalineFormulaPickerDropdown
                                noBaseUnit={values?.emission_source?.has_children}
                                conversionLogic={values?.emission_source?.unit_conversion_logic}
                                calculationType={values?.calculation_type}
                                setCalculationType={(newCalcType) => {
                                    setFieldValue('calculation_type', newCalcType);
                                }}
                                fields={values.fields}
                                setFieldValue={setFieldValue}
                                baseUnit={values?.emission_source?.base_unit}
                                amount={values.amount}
                                defaultField={defaultField}
                                setAmount={(amount) => {
                                    setFieldValue('amount', amount);
                                }}
                                systemManagedDataline={systemManagedDataline}
                                systemManagedEmissionSource={systemManagedEmissionSource}
                                closedYear={closedYear}
                                isAirportCalculatorVisible={isAirportCalculatorVisible}
                                notes={values.notes}
                                setShowNotes={setShowNotes}
                                setDisabledByAirportCalculator={setDisabledByAirportCalculator}
                            />
                        </Grid>
                        {showNotes && (
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    multiline
                                    name="notes"
                                    autoComplete="off"
                                    label="Notes (optional, incl. in Audit Report)"
                                    value={values.notes || ''}
                                    onChange={(ev) => setFieldValue('notes', ev.target.value)}
                                    // notes can be edited in case of systemManagedDataline
                                    disabled={
                                        (!basicModifyDatalinePermission &&
                                            !complexModifyDatalinePermission) ||
                                        closedYear
                                    }
                                />
                            </Grid>
                        )}
                        {showCost && (
                            <Grid item xs={6}>
                                <FloatInput
                                    fullWidth
                                    label="Cost (optional)"
                                    type="number"
                                    autoComplete="off"
                                    value={values.cost}
                                    onChange={(ev) => setFieldValue('cost', ev.target.rawValue)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">$</InputAdornment>
                                        ),
                                    }}
                                    disabled={
                                        (!basicModifyDatalinePermission &&
                                            !complexModifyDatalinePermission) ||
                                        systemManagedDataline ||
                                        systemManagedEmissionSource ||
                                        closedYear
                                    }
                                />
                            </Grid>
                        )}
                        {showAssign && (
                            <Grid item xs={6}>
                                <AutocompleteSelect
                                    initCall={userAutocomplete.init}
                                    autocompleteCall={userAutocomplete.search}
                                    value={values.assigned_user?.user_id}
                                    setValue={(ev) => {
                                        setFieldValue('assigned_user.user_id', ev);
                                    }}
                                    disabled={
                                        !complexModifyDatalinePermission ||
                                        systemManagedDataline ||
                                        systemManagedEmissionSource ||
                                        closedYear
                                    }
                                >
                                    <TextField label="Assigned user" />
                                </AutocompleteSelect>
                            </Grid>
                        )}
                        {showOwner && (
                            <Grid item xs={6}>
                                <AutocompleteSelect
                                    initCall={userAutocomplete.init}
                                    autocompleteCall={userAutocomplete.search}
                                    value={values?.owner_user?.user_id}
                                    setValue={(ev) => {
                                        setFieldValue('owner_user.user_id', ev);
                                    }}
                                    disabled={
                                        !complexModifyDatalinePermission ||
                                        systemManagedDataline ||
                                        systemManagedEmissionSource ||
                                        closedYear
                                    }
                                >
                                    <TextField label="Owner" />
                                </AutocompleteSelect>
                            </Grid>
                        )}
                        {showDate && (
                            <>
                                <Grid item xs={6}>
                                    <InputLabel>From date</InputLabel>
                                    <TextField
                                        fullWidth
                                        type="date"
                                        id="from_date"
                                        name="from_date"
                                        value={values.from_date}
                                        onChange={(ev) =>
                                            setFieldValue('from_date', ev.target.value)
                                        }
                                        error={touched.from_date && Boolean(errors.from_date)}
                                        helperText={touched.from_date ? errors.from_date : ''}
                                        disabled={
                                            (!basicModifyDatalinePermission &&
                                                !complexModifyDatalinePermission) ||
                                            systemManagedDataline ||
                                            systemManagedEmissionSource ||
                                            closedYear
                                        }
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabel>To date</InputLabel>
                                    <TextField
                                        fullWidth
                                        type="date"
                                        id="to_date"
                                        name="to_date"
                                        value={values.to_date}
                                        onChange={(ev) => setFieldValue('to_date', ev.target.value)}
                                        error={touched.to_date && Boolean(errors.to_date)}
                                        helperText={touched.to_date ? errors.to_date : ''}
                                        disabled={
                                            (!basicModifyDatalinePermission &&
                                                !complexModifyDatalinePermission) ||
                                            systemManagedDataline ||
                                            systemManagedEmissionSource ||
                                            closedYear
                                        }
                                    />
                                </Grid>
                            </>
                        )}
                        {showDocuments && (
                            <DocumentField
                                values={values}
                                setFieldValue={setFieldValue}
                                basicModifyDatalinePermission={basicModifyDatalinePermission}
                                complexModifyDatalinePermission={complexModifyDatalinePermission}
                                systemManagedDataline={systemManagedDataline}
                                systemManagedEmissionSource={systemManagedEmissionSource}
                                closedYear={closedYear}
                            />
                        )}
                        {showDatalineTags && (
                            // dataline tags can be edited in case of systemManagedDataline
                            <FreeSoloDatalineTagAutoComplete
                                fullWidth
                                label="Tags"
                                value={values?.datalineTags}
                                setValue={(newValue) => setFieldValue('datalineTags', newValue)}
                                percentages={percentages}
                                setPercentages={setPercentages}
                                basicModifyDatalinePermission={basicModifyDatalinePermission}
                                complexModifyDatalinePermission={complexModifyDatalinePermission}
                                closedYear={closedYear}
                            >
                                <TextField label="Dataline tags" required />
                            </FreeSoloDatalineTagAutoComplete>
                        )}
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                {!showNotes &&
                                    (basicModifyDatalinePermission ||
                                        complexModifyDatalinePermission) &&
                                    !closedYear && (
                                        // notes can be edited in case of systemManagedDataline
                                        <AddItemButton
                                            label="Note"
                                            onClick={() => setShowNotes(true)}
                                        />
                                    )}
                                {!showDocuments &&
                                    (basicModifyDatalinePermission ||
                                        complexModifyDatalinePermission) &&
                                    !systemManagedDataline &&
                                    !systemManagedEmissionSource &&
                                    !closedYear && (
                                        <AddItemButton
                                            label="Document"
                                            onClick={() => setShowDocuments(true)}
                                        />
                                    )}
                                {!showAssign &&
                                    complexModifyDatalinePermission &&
                                    !systemManagedDataline &&
                                    !systemManagedEmissionSource &&
                                    !closedYear && (
                                        <AddItemButton
                                            label="Assign"
                                            onClick={() => setShowAssign(true)}
                                        />
                                    )}
                                {!showOwner &&
                                    complexModifyDatalinePermission &&
                                    !systemManagedDataline &&
                                    !systemManagedEmissionSource &&
                                    !closedYear && (
                                        <AddItemButton
                                            label="Owner"
                                            onClick={() => setShowOwner(true)}
                                        />
                                    )}
                                {!showDate &&
                                    (basicModifyDatalinePermission ||
                                        complexModifyDatalinePermission) &&
                                    !systemManagedDataline &&
                                    !systemManagedEmissionSource &&
                                    !closedYear && (
                                        <AddItemButton
                                            label="Dates (from-to)"
                                            onClick={() => setShowDate(true)}
                                        />
                                    )}
                                {!showCost &&
                                    (basicModifyDatalinePermission ||
                                        complexModifyDatalinePermission) &&
                                    !systemManagedDataline &&
                                    !systemManagedEmissionSource &&
                                    !closedYear && (
                                        <AddItemButton
                                            label="Cost"
                                            onClick={() => setShowCost(true)}
                                        />
                                    )}
                                {!showDatalineTags &&
                                    (basicModifyDatalinePermission ||
                                        complexModifyDatalinePermission) &&
                                    !closedYear && (
                                        // dataline tags can be edited in case of systemManagedDataline
                                        <AddItemButton
                                            label="Dataline tags"
                                            onClick={() => setShowDatalineTags(true)}
                                        />
                                    )}
                                <Grid item style={{ flex: '1 1 0' }}>
                                    <Grid container spacing={2} justifyContent="flex-end">
                                        <Grid item>
                                            <CommonButton
                                                fullWidth
                                                onClick={() => setEdited(false)}
                                                label="Cancel"
                                                buttonType="secondary"
                                            />
                                        </Grid>
                                        {!closedYear && (
                                            <Grid item>
                                                <CommonButton
                                                    // notes & dataline tags can be edited in case of systemManagedDataline
                                                    fullWidth
                                                    disabled={
                                                        isSubmitting ||
                                                        disabledByAirportCalculator ||
                                                        (!basicModifyDatalinePermission &&
                                                            !complexModifyDatalinePermission)
                                                    }
                                                    type="submit"
                                                    label="Save"
                                                    buttonType="primary"
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {values.modified_user?.user_name && values?.modified_at && (
                            <Grid item xs={12} pt={0}>
                                <LastModified
                                    modifiedUser={values?.modified_user}
                                    modifiedAt={values?.modified_at}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Form>
            )}
        </Formik>
    );
}

export default DatalineForm;
