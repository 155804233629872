import React from 'react';
import { Breadcrumbs, Grid, Typography } from '@mui/material';
import { Lock as LockIcon } from '@mui/icons-material';

function BreadCrumbTitle({
    breadcrumbList,
    path,
    setCurrentPath,
    setLocked,
    setCurrentPathIds,
    currentPathIds,
    isEsgScenario = false,
}) {
    return (
        <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
                {breadcrumbList.map((breadcrumb, idx) => (
                    <Typography
                        key={idx}
                        variant="h5"
                        sx={{
                            fontWeight: 'bold',
                            cursor: breadcrumbList.length - 1 === idx ? 'auto' : 'pointer',
                            textDecoration:
                                breadcrumbList.length - 1 === idx ? 'none' : 'underline',
                        }}
                    >
                        {breadcrumb.locked && !isEsgScenario && (
                            <LockIcon
                                sx={{ mr: 1 }}
                                style={{ fontSize: '80%' }}
                                onClick={() => {
                                    setLocked(path.slice(0, idx), false);
                                }}
                            />
                        )}
                        <a
                            onClick={() => {
                                setCurrentPath(path.slice(0, idx));
                                setCurrentPathIds(currentPathIds.slice(0, idx));
                            }}
                        >
                            {breadcrumb.name}
                        </a>
                    </Typography>
                ))}
            </Breadcrumbs>
        </Grid>
    );
}

export default BreadCrumbTitle;
