import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import CustomTreeItem from '../../atom/CustomTreeItem';
import EsgQuestionLevel from './EsgQuestionLevel';
import transformTreeToFlat from '../helpers/transformTreeToFlat';

function EsgDisclosureLevel({
    checkedData,
    esg_disclosure,
    setCheckedData,
    getCheckedChildrenNumber,
    isScenario = false,
}) {
    const checkedChildren = getCheckedChildrenNumber(esg_disclosure);
    const esgDisclosureLabel =
        checkedChildren === 0
            ? `${esg_disclosure.name}`
            : `${esg_disclosure.name} (+ ${checkedChildren} more)`;
    return (
        <CustomTreeItem
            nodeId={`esg_disclosure - ${esg_disclosure.id}`}
            label={
                <FormControlLabel
                    label={esgDisclosureLabel}
                    labelPlacement="end"
                    control={
                        <Checkbox
                            checked={
                                !!checkedData.find(
                                    (c) => c.id === esg_disclosure.id && c.type === 'disclosure'
                                )
                            }
                            onChange={() => {
                                setCheckedData((old) => {
                                    const findCurrent = old.find(
                                        (item) =>
                                            item.id === esg_disclosure.id &&
                                            item.type === 'disclosure'
                                    );
                                    if (findCurrent) {
                                        // find current item's children
                                        const children = transformTreeToFlat(
                                            esg_disclosure.children
                                        );
                                        return old.filter(
                                            (data) =>
                                                data !== findCurrent &&
                                                !children.some(
                                                    (child) =>
                                                        child.id === data.id &&
                                                        child.type === data.type
                                                )
                                        );
                                    }
                                    return [
                                        ...old,
                                        {
                                            type: 'disclosure',
                                            id: esg_disclosure.id,
                                        },
                                        ...transformTreeToFlat(esg_disclosure.children),
                                    ];
                                });
                            }}
                        />
                    }
                />
            }
        >
            {esg_disclosure.children.map((esgQuestion) => (
                <EsgQuestionLevel
                    key={`${esgQuestion.id} - ${esgQuestion.name}`}
                    checkedData={checkedData}
                    esg_question={esgQuestion}
                    setCheckedData={setCheckedData}
                    isScenario={isScenario}
                />
            ))}
        </CustomTreeItem>
    );
}

export default EsgDisclosureLevel;
