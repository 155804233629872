import React, { useCallback, useEffect, useState } from 'react';
import { Grid, TextField, Paper, Chip, Alert } from '@mui/material';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import SwitchLeftIcon from '@mui/icons-material/SwitchLeft';
import SwitchRightIcon from '@mui/icons-material/SwitchRight';
import { doSwaggerCall } from '../../hooks/useApi';
import useNotify from '../../hooks/useNotify';
import ComponentTitle from '../../components/ComponentTitle';
import { useAuth } from '../../hooks/useAuth';
import navigateByPermission from '../../helpers/navigateByPermission';
import { ROLES } from '../../helpers/constans';
import NotificationSettingsPicker from './components/NotificationSettingsPicker';
import BlockWithTitle from '../../components/atom/BlockWithTitle';
import TwoFactorSettings from './components/TwoFactorSettings';
import UploadImageInput from '../../components/atom/UploadImageInput';
import CommonButton from '../../components/atom/CommonButton';
import { MODAL_TYPE, useModals } from '../../hooks/useModal';
import { useGlobalFilters } from '../../hooks/useGlobalFilters';

function convertSettingsToLines(input) {
    const notificationLines = [];
    Object.entries(input).map(([key, value]) => {
        notificationLines.push({
            id: `${Math.random()}`.substring(2),
            type: key,
            frequency: value,
        });
    });
    return notificationLines;
}

function convertSettingsToLogic(newNotificationLines) {
    const fieldValue = {};
    for (let i = 0; i < newNotificationLines.length; i++) {
        fieldValue[newNotificationLines[i].type] = newNotificationLines[i].frequency;
    }
    return fieldValue;
}

const CookieSettings = ({ requestDeletion }) => {
    return (
        <>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', pt: 4 }}>
                <small
                    onClick={() => {
                        window.cookiedone.showCookieSettings();
                    }}
                    style={{ cursor: 'pointer', fontWeight: 'bold' }}
                >
                    Modify cookie settings
                </small>
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', pt: 2 }}>
                <small onClick={requestDeletion} style={{ cursor: 'pointer' }}>
                    Request account deletion
                </small>
            </Grid>
        </>
    );
};

function ProfilePage() {
    const { user, setUser, setSwitchIntoCompany, setAccessToken } = useAuth();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [data, setData] = useState(null);
    const { notifyError, notifySuccess } = useNotify();
    const navigateUrl = navigateByPermission();
    const [userProfilePicture, setUserProfilePicture] = useState(null);
    const [twoFactorSecret, setTwoFactorSecret] = useState(null);
    const [twoFactorChanged, setTwoFactorChanged] = useState(false);
    const [showTwoFactor, setShowTwoFactor] = useState(false);
    const { showModal } = useModals();
    const { setSourceFilters } = useGlobalFilters();

    const loadUser = useCallback(() => {
        setLoading(true);
        doSwaggerCall('Users', 'getMe', {})
            .then((res) => {
                setData({
                    id: res.id,
                    name: res.name,
                    email: res.email,
                    profile_picture: res.profile_picture,
                    companies: res.companies,
                    notification_settings: convertSettingsToLines(res.notification_settings),
                });
                setUserProfilePicture(res.profile_picture);
                setTwoFactorSecret(res.two_factor_secret);
                setShowTwoFactor(res.showTwoFactor);
                setLoading(false);
                setTwoFactorChanged(false);
            })
            .catch((err) => {
                setLoading(false);
                setTwoFactorChanged(false);
                notifyError(err);
            });
    }, [setData, setLoading, notifyError]);

    useEffect(() => {
        loadUser();
    }, [twoFactorChanged]);

    useEffect(() => {
        // hack to update profile on top
        setUser({
            ...user,
            profile_picture: userProfilePicture,
        });
    }, [userProfilePicture]);

    const onSubmit = async (values, { setSubmitting }) => {
        try {
            await doSwaggerCall(
                'Users',
                'editMe',
                {},
                {
                    name: values.name,
                    email: values.email,
                    profile_picture: values.profile_picture,
                    notification_settings: convertSettingsToLogic(values.notification_settings),
                }
            );
            loadUser();
            setSubmitting(false);
            notifySuccess('User profile updated');
        } catch (err) {
            notifyError(err);
            setSubmitting(false);
        }
    };

    const switchCompany = useCallback(
        (id) => {
            doSwaggerCall('Auth', 'switchCompany', {
                companyId: id,
            })
                .then((switchCompanyData) => {
                    const { token, ...otherUserData } = switchCompanyData.user;
                    setSwitchIntoCompany(otherUserData);
                    setAccessToken(token);
                    setSourceFilters({
                        scopeFilter: null,
                        categoryFilter: null,
                        subcategoryFilter: null,
                        emissionSourceFilter: null,
                    });
                    notifySuccess('Switched into company');
                    loadUser();
                })
                .catch((error) => {
                    notifyError(error);
                });
        },
        [setSwitchIntoCompany, setAccessToken]
    );

    const requestDeletion = () => {
        showModal(MODAL_TYPE.CONFIRMATION, {
            title: 'Request account deletion',
            content: 'Are you sure you want to request the deletion of your account?',
            warningContent: 'This action may takes time to delete your account.',
            confirm: () => {
                doSwaggerCall('Users', 'requestAccountDeletion', {})
                    .then(() => {
                        notifySuccess('Account deletion requested');
                    })
                    .catch((error) => {
                        notifyError(error);
                    });
            },
        });
    };

    if (loading) {
        return true;
    }

    const validateSchema = Yup.object().shape({
        name: Yup.string().required('Required'),
    });

    return (
        <Formik onSubmit={onSubmit} initialValues={data} validationSchema={validateSchema}>
            {({ isSubmitting, values, setFieldValue, touched, errors, dirty }) => (
                <Form>
                    <Grid container justifyContent="space-between">
                        <Grid
                            item
                            xs={
                                !showTwoFactor &&
                                !user.two_factor_enabled &&
                                user.role !== ROLES.USER
                                    ? 10
                                    : 6
                            }
                        >
                            <Grid item xs={12}>
                                <Paper sx={{ p: 2, mt: 2 }}>
                                    <Grid container>
                                        <Grid item>
                                            <ComponentTitle variant="h4" title="Profile" />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Name"
                                                autoComplete="off"
                                                name="name"
                                                value={values.name}
                                                onChange={(ev) =>
                                                    setFieldValue('name', ev.target.value)
                                                }
                                                error={touched.name && Boolean(errors.name)}
                                                helperText={touched.name ? errors.name : ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                autoComplete="off"
                                                label="Email"
                                                value={values.email}
                                                disabled
                                            />
                                        </Grid>
                                        <UploadImageInput
                                            value={values?.profile_picture}
                                            setFieldValue={setFieldValue}
                                            isProfile
                                            picture="profile_picture"
                                        />
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sx={{ pt: 2 }}>
                                <BlockWithTitle title="Notification settings">
                                    <Grid item xs={12}>
                                        <Alert severity="info">
                                            You can set the frequency of notifications for your GHG
                                            and ESG data.
                                        </Alert>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <NotificationSettingsPicker
                                            values={values}
                                            setFieldValue={setFieldValue}
                                        />
                                    </Grid>
                                </BlockWithTitle>
                            </Grid>
                            <Grid container item xs={12} sx={{ pt: 2 }}>
                                <Grid item xs={6}>
                                    <CommonButton
                                        fullWidth
                                        onClick={() => navigate(navigateUrl)}
                                        label="Cancel"
                                        buttonType="secondary"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <CommonButton
                                        fullWidth
                                        disabled={isSubmitting || !dirty}
                                        type="submit"
                                        label="Save"
                                        buttonType="primary"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        {showTwoFactor || user.two_factor_enabled || user.role === ROLES.USER ? (
                            <Grid item xs={6}>
                                {user.role === ROLES.USER && (
                                    <Grid item xs={12}>
                                        <Paper sx={{ p: 2, mt: 2 }}>
                                            <Grid item xs={6}>
                                                <ComponentTitle variant="h4" title="Companies" />
                                            </Grid>
                                            {data.companies.map((e) => {
                                                return (
                                                    <Chip
                                                        key={e.id}
                                                        sx={{ mr: 1, mt: 1.5, mb: 1.5 }}
                                                        label={e.name}
                                                        size="medium"
                                                        color="primary"
                                                        icon={
                                                            user?.company_id === e.id ? (
                                                                <SwitchRightIcon />
                                                            ) : (
                                                                <SwitchLeftIcon disabled />
                                                            )
                                                        }
                                                        onClick={() => {
                                                            switchCompany(e.id);
                                                        }}
                                                        disabled={user?.company_id === e.id}
                                                    />
                                                );
                                            })}
                                        </Paper>
                                    </Grid>
                                )}
                                {(showTwoFactor || user.two_factor_enabled) && (
                                    <Grid item xs={12} sx={{ pt: 2 }}>
                                        <BlockWithTitle title="Two Factor Authentication Settings">
                                            <Grid item xs={12}>
                                                <TwoFactorSettings
                                                    userId={user.id}
                                                    twoFactorSecret={twoFactorSecret}
                                                    setTwoFactorChanged={setTwoFactorChanged}
                                                />
                                            </Grid>
                                        </BlockWithTitle>
                                    </Grid>
                                )}
                                <CookieSettings requestDeletion={requestDeletion} />
                            </Grid>
                        ) : (
                            <Grid item xs={2}>
                                <CookieSettings requestDeletion={requestDeletion} />
                            </Grid>
                        )}
                    </Grid>
                </Form>
            )}
        </Formik>
    );
}

export default ProfilePage;
