import { Autocomplete, CircularProgress, FormControl, Grid, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { doSwaggerCall } from '../../../hooks/useApi';
import errorMessage from '../helpers/airportHelpers';

const AirportDistanceFormula = ({
    logic,
    fields,
    setFields,
    setDisabledByAirportCalculator,
    closedYear,
}) => {
    const [airportFrom, setAirportFrom] = useState(null);
    const [airportTo, setAirportTo] = useState(null);
    const [distance, setDistance] = useState(null);
    const [allAirports, setAllAirports] = useState([]);
    const airportListLoading = allAirports.length === 0;

    // runs once when the component is mounted
    useEffect(() => {
        // TODO: fix limit
        doSwaggerCall('AirportDistances', 'getAirportList', { limit: 1000000 }).then((res) => {
            const { airports } = res;
            setAllAirports(airports);
            setAirportFrom(
                airports.find((airport) => airport.short === fields[logic.fields[0].field_id])
            );
            setAirportTo(
                airports.find((airport) => airport.short === fields[logic.fields[1].field_id])
            );
        });
    }, []);

    // calculate distance if both airports are selected
    useEffect(() => {
        if (!airportFrom || !airportTo) {
            return;
        }

        doSwaggerCall('AirportDistances', 'getOneDistance', {
            fromAirport: airportFrom.short,
            toAirport: airportTo.short,
        })
            .then((res) => {
                setDistance(res.distance);
            })
            .catch(() => {
                // no distance found
                setDistance(null);
            });
    }, [airportFrom, airportTo]);

    // check if the distance is calculated and set the disabled state of the calculator
    useEffect(() => {
        const errorMessageResult = errorMessage(airportFrom, airportTo, distance);
        setDisabledByAirportCalculator(errorMessageResult !== '');
    }, [airportFrom, airportTo, distance]);

    return (
        <Grid container item xs={12}>
            <Grid item xs={4}>
                <FormControl fullWidth>
                    <Autocomplete
                        disabled={closedYear}
                        options={allAirports}
                        fullWidth
                        getOptionLabel={(option) => {
                            const label = option.city
                                ? `${option.short} - (${option.city})`
                                : option.short;

                            return option.short ? label : '';
                        }}
                        filterOptions={(options, state) => {
                            const searchTerm = state.inputValue?.toLowerCase();
                            return options.filter(
                                (option) =>
                                    option.short?.toLowerCase().includes(searchTerm) ||
                                    option.city?.toLowerCase().includes(searchTerm)
                            );
                        }}
                        renderInput={(params) => (
                            <TextField
                                required
                                {...params}
                                label="Origin Airport"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {params.InputProps.endAdornment}
                                            {airportListLoading && (
                                                <CircularProgress color="inherit" size={15} />
                                            )}
                                        </>
                                    ),
                                }}
                            />
                        )}
                        onChange={(_, newValue) => {
                            setAirportFrom(newValue);
                            setDistance(null);
                            setFields({
                                ...fields,
                                [logic.fields[0].field_id]: newValue?.short,
                            });
                        }}
                        value={airportFrom || null}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <FormControl fullWidth>
                    <Autocomplete
                        disabled={closedYear}
                        options={allAirports}
                        fullWidth
                        getOptionLabel={(option) => {
                            const label = option.city
                                ? `${option.short} - (${option.city})`
                                : option.short;

                            return option.short ? label : '';
                        }}
                        filterOptions={(options, state) => {
                            const searchTerm = state.inputValue?.toLowerCase();
                            return options.filter(
                                (option) =>
                                    option.short?.toLowerCase().includes(searchTerm) ||
                                    option.city?.toLowerCase().includes(searchTerm)
                            );
                        }}
                        renderInput={(params) => (
                            <TextField
                                required
                                {...params}
                                label="Destination Airport"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {params.InputProps.endAdornment}
                                            {airportListLoading && (
                                                <CircularProgress color="inherit" size={15} />
                                            )}
                                        </>
                                    ),
                                }}
                            />
                        )}
                        onChange={(_, newValue) => {
                            setAirportTo(newValue);
                            setDistance(null);
                            setFields({
                                ...fields,
                                [logic.fields[1].field_id]: newValue?.short,
                            });
                        }}
                        value={airportTo || null}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={4} sx={{ alignSelf: 'center', textAlign: 'center' }}>
                <b>Distance:</b>{' '}
                {distance ? `${distance} miles` : errorMessage(airportFrom, airportTo, distance)}
            </Grid>
        </Grid>
    );
};

export default AirportDistanceFormula;
