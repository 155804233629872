import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import useNotify from '../../hooks/useNotify';
import useSorting from '../../hooks/useSorting';
import { TABLE_ROW_COUNT_DEFAULT } from '../../helpers/constans';
import { useApi } from '../../hooks/useApi';
import ComponentTitle from '../../components/ComponentTitle';
import CommonButton from '../../components/atom/CommonButton';
import EsgScenariosTable from './components/EsgScenariosTable';
import SearchInput from '../../components/atom/SearchInput';
import ScenarioTabSwitcher from '../../components/atom/ScenarioTabSwitcher';

const EsgScenariosPage = () => {
    const navigate = useNavigate();
    const { notifyError } = useNotify();
    const [search, setSearch] = useState('');
    const { sort, sortDir, setSorting } = useSorting(['created_at', 'ASC']);
    const [{ limit, page }, setParams] = useState({ limit: TABLE_ROW_COUNT_DEFAULT, page: 0 });
    const [data, loading, error, setData, reloadData] = useApi('EsgScenarios', 'getEsgScenarios', {
        search,
        limit,
        offset: page * limit,
        sort,
        sortDir,
    });

    useEffect(() => {
        if (error) {
            notifyError(error);
        }
    }, [error, notifyError]);

    return (
        <Grid container alignContent="flex-start">
            <ScenarioTabSwitcher />
            <Grid container item xs={12} justifyContent="space-between" spacing={2}>
                <Grid item xs={12}>
                    <SearchInput
                        search={search}
                        setSearch={setSearch}
                        setParams={setParams}
                        limit={limit}
                        fullWidth
                    />
                </Grid>
                <Grid item>
                    <ComponentTitle variant="h4" title="ESG Targets and plannings" />
                </Grid>
                <Grid item>
                    <CommonButton
                        fullWidth
                        onClick={() => navigate('/esgscenarios/new')}
                        label="Add scenario"
                        buttonType="primary"
                    />
                </Grid>
            </Grid>
            <EsgScenariosTable
                rows={data?.scenarios}
                limit={limit}
                page={page}
                setParams={setParams}
                total={data?.total}
                loading={loading}
                reloadData={reloadData}
                sort={sort}
                sortDir={sortDir}
                setSorting={setSorting}
            />
        </Grid>
    );
};

export default EsgScenariosPage;
