import React, { useState } from 'react';
import {
    DialogActions,
    DialogContent,
    DialogTitle,
    Dialog,
    Grid,
    TextField,
    Tooltip,
} from '@mui/material';
import { doSwaggerCall } from '../hooks/useApi';
import useNotify from '../hooks/useNotify';
import CommonButton from '../components/atom/CommonButton';

const CopyScenarioModal = ({
    title,
    row,
    showModal,
    MODAL_TYPE,
    reloadData,
    scenarioType = 'ghg',
}) => {
    const closeAction = () => showModal(MODAL_TYPE.NONE);
    const { notifySuccess, notifyError } = useNotify();
    const [scenarioName, setScenarioName] = useState('');

    const addScenario = async () => {
        const commonScenarioData = {
            name: scenarioName,
            baselineYear: row.baseline_year,
            targetYear: row.target_year,
            siteFilter: row.site_filter,
        };

        const handleAddScenario = async (service, action, data) => {
            try {
                await doSwaggerCall(service, action, {}, data);
                notifySuccess('Scenario added');
                reloadData();
            } catch (err) {
                notifyError(err);
            }
        };

        if (scenarioType === 'ghg') {
            const ghgScenarioData = {
                ...commonScenarioData,
                targetReduction: row.target_reduction,
                targetScopes: row.target_scopes,
                targetCategories: row.target_categories,
                targetScopesHierarchy: row.target_scopes_hierarchy,
                datalineTagId: row.dataline_tag_id,
            };
            await handleAddScenario('Scenarios', 'addScenario', ghgScenarioData);
        } else {
            const esgScenarioData = {
                ...commonScenarioData,
                targetHierarchy: row.target_hierarchy,
                esgTagId: row.esg_tag_id,
            };
            await handleAddScenario('EsgScenarios', 'addEsgScenario', esgScenarioData);
        }
    };

    return (
        <Dialog
            open
            onClose={closeAction}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xs"
            fullWidth
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <Grid item xs={12} sx={{ mt: 2 }}>
                    <TextField
                        label="Scenario name"
                        autoComplete="off"
                        required
                        fullWidth
                        onChange={(ev) => setScenarioName(ev.target.value)}
                    />
                </Grid>
            </DialogContent>
            <DialogActions>
                <CommonButton
                    onClick={closeAction}
                    autoFocus
                    label="Cancel"
                    buttonType="secondary"
                />
                {!scenarioName ? (
                    <Tooltip title="Scenario name is required" placement="left">
                        <span>
                            <CommonButton
                                disabled
                                sx={{ mr: 1 }}
                                label="Yes"
                                buttonType="primary"
                            />
                        </span>
                    </Tooltip>
                ) : (
                    <CommonButton
                        onClick={() => {
                            addScenario();
                            showModal(MODAL_TYPE.NONE);
                        }}
                        label="Yes"
                        buttonType="primary"
                    />
                )}
            </DialogActions>
        </Dialog>
    );
};

export default CopyScenarioModal;
