import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { doSwaggerCall } from '../../../hooks/useApi';
import useNotify from '../../../hooks/useNotify';
import EsgEditForm from '../components/EsgEditForm';

function EditEsgCategoryPage({ newEsgCategory }) {
    const { esgCategoryId } = useParams();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [category, setCategory] = useState(null);
    const { notifyError, notifySuccess } = useNotify();

    const defaultCategory = {
        name: 'New ESG Category',
        rank: 1,
    };

    const loadCategory = useCallback(() => {
        setLoading(true);
        doSwaggerCall('EsgCategories', 'getEsgCategory', { id: esgCategoryId })
            .then((res) => {
                setCategory({
                    id: res.id,
                    name: res.name,
                    rank: res.rank,
                });
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                notifyError(err);
                navigate('/esgcategories');
            });
    }, []);

    useEffect(() => {
        if (!newEsgCategory) {
            loadCategory();
        } else {
            setLoading(false);
        }
    }, [esgCategoryId]);

    const onSubmit = async (values, { setSubmitting }) => {
        try {
            if (newEsgCategory) {
                await doSwaggerCall(
                    'EsgCategories',
                    'addEsgCategory',
                    {},
                    {
                        name: values.name.trim(),
                        rank: parseInt(values.rank, 10),
                    }
                );
                navigate('/esgcategories');
                notifySuccess('ESG category added');
            } else {
                await doSwaggerCall(
                    'EsgCategories',
                    'editEsgCategory',
                    { id: esgCategoryId },
                    {
                        name: values.name.trim(),
                        rank: parseInt(values.rank, 10),
                    }
                );
                loadCategory();
                setSubmitting(false);
                navigate('/esgcategories');
                notifySuccess('ESG category updated');
            }
        } catch (err) {
            notifyError(err);
            setSubmitting(false);
        }
    };

    if (loading) {
        return true;
    }

    return (
        <EsgEditForm
            initialValues={category || { ...defaultCategory }}
            onSubmit={onSubmit}
            navigateBack="/esgcategories"
            title="ESG Category"
            isNew={newEsgCategory}
        />
    );
}

export default EditEsgCategoryPage;
