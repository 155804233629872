import {
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
} from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import SortableTableCell from '../../../components/atom/SortableTableCell';
import SkeletonTable from '../../../components/atom/SkeletonTable';
import { doSwaggerCall } from '../../../hooks/useApi';
import useNotify from '../../../hooks/useNotify';
import { TABLE_ROW_COUNT_OPTIONS } from '../../../helpers/constans';
import { handleChangeRowsPerPage, handlePageChange } from '../../../helpers/handleTableFunctions';
import { useEsgFilters } from '../../../hooks/useEsgFilters';
import { useGlobalFilters } from '../../../hooks/useGlobalFilters';
import { formatNumber } from '../../../helpers/convertUnits';

const TABLE_HEADERS = [
    { label: 'Question', sortKey: 'question' },
    { label: 'Site', sortKey: 'sitename' },
    { label: 'Values' },
    { label: 'Assignee', sortKey: 'assignee', align: 'center' },
    { label: 'Owner', sortKey: 'owner', align: 'center' },
    { label: 'Field filled', align: 'center' },
];

const BlankEsgsTable = ({
    rows,
    loading,
    sort,
    sortDir,
    setSorting,
    total,
    page,
    setParams,
    limit,
}) => {
    const navigate = useNavigate();
    const { setEsgFilters } = useEsgFilters();
    const { setSiteFilters, setSelectedSites } = useGlobalFilters();
    const { notifyError } = useNotify();

    const navigateByPermission = (row) => {
        const navigateWhenHaveData = () => {
            setSiteFilters({
                siteFilter: [row.site_id === null ? 0 : row.site_id],
                siteNamesFilter: [row.site_name],
            });
            setSelectedSites([row.site_id === null ? 0 : row.site_id]);
            navigate('/esgs');
        };

        try {
            doSwaggerCall('EsgQuestions', 'getAllEsgQuestions', {
                idList: [row.question_id],
            }).then(({ esgQuestions }) => {
                setEsgFilters({
                    esgCategoryFilter: esgQuestions[0]?.category_id,
                    esgCategoryNameFilter: esgQuestions[0]?.category_name || null,
                    esgSubcategoryFilter: esgQuestions[0]?.subcategory_id,
                    esgSubcategoryNameFilter: esgQuestions[0]?.subcategory_name || null,
                    esgDisclosureFilter: esgQuestions[0]?.disclosure_id,
                    esgDisclosureNameFilter: esgQuestions[0]?.disclosure_name || null,
                    esgQuestionFilter: esgQuestions[0]?.id || null,
                    esgQuestionNameFilter: esgQuestions[0]?.name || null,
                });
                navigateWhenHaveData();
            });
        } catch (e) {
            notifyError(e);
        }
    };

    return (
        <Grid item xs={12}>
            <TableContainer component={Paper}>
                <Table aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            {TABLE_HEADERS.map((header) => (
                                <SortableTableCell
                                    key={header.label}
                                    arrow={sort === header.sortKey ? sortDir : ''}
                                    onClick={() => setSorting(header.sortKey)}
                                    justifyContent={header.align}
                                >
                                    {header.label}
                                </SortableTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <SkeletonTable rows={10} cells={TABLE_HEADERS.length + 1} />
                        ) : (
                            rows?.map((row) => {
                                return (
                                    <TableRow key={`${row.levelType} - ${row.id}`}>
                                        <TableCell>
                                            <Tooltip title="Jump to ESGs" placement="right">
                                                <IconButton
                                                    onClick={() => navigateByPermission(row)}
                                                >
                                                    <CloseIcon color="error" />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell>{row.question}</TableCell>
                                        <TableCell>{row.site_name || '-'}</TableCell>
                                        <TableCell>
                                            {row.values.length > 0
                                                ? row.values.map((field) => (
                                                      <p>
                                                          {
                                                              row.esg_question_fields.find(
                                                                  (e) => e.id === field?.id
                                                              )?.label
                                                          }
                                                          :{' '}
                                                          {row.esg_question_fields.find(
                                                              (e) => e.id === field?.id
                                                          )?.type === 'number'
                                                              ? formatNumber(field?.value)
                                                              : field?.value}
                                                      </p>
                                                  ))
                                                : `-`}
                                        </TableCell>
                                        <TableCell align="center">
                                            {row.assignedto_name || '-'}
                                        </TableCell>
                                        <TableCell align="center">
                                            {row.owner_name || '-'}
                                        </TableCell>
                                        <TableCell align="center">{`${row.field_filled_out}/${row.field_count}`}</TableCell>
                                    </TableRow>
                                );
                            })
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                count={total || 0}
                rowsPerPage={limit}
                component="div"
                page={page}
                onPageChange={(_, newPage) => handlePageChange(newPage, setParams, limit)}
                rowsPerPageOptions={TABLE_ROW_COUNT_OPTIONS}
                onRowsPerPageChange={(event) => handleChangeRowsPerPage(event, setParams)}
            />
        </Grid>
    );
};

export default BlankEsgsTable;
