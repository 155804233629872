import {
    Checkbox,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteIcon from '@mui/icons-material/Delete';
import DeselectIcon from '@mui/icons-material/Deselect';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { MODAL_TYPE, useModals } from '../../../hooks/useModal';
import { doSwaggerCall, uploadDocumentToMultipleEsgs } from '../../../hooks/useApi';
import useNotify from '../../../hooks/useNotify';
import SkeletonTable from '../../../components/atom/SkeletonTable';
import SortableTableCell from '../../../components/atom/SortableTableCell';
import { TABLE_ROW_COUNT_OPTIONS } from '../../../helpers/constans';
import TableDetails from '../../../components/atom/TableDetails';
import EditEsgPage from '../../esgedit/EditEsgPage';
import { handleChangeRowsPerPage, handlePageChange } from '../../../helpers/handleTableFunctions';
import MultipleLineSelect from '../../../components/atom/MultipleLineSelect';
import ResponsibilityTableCell from '../../../components/atom/ResponsibilityTableCell';
import TableSelectDropdown from './TableSelectDropdown';
import { WITHOUT_UNIT } from '../../../helpers/esgUnitConversions';
import { formatNumber } from '../../../helpers/convertUnits';

const EsgsTable = ({
    rows,
    limit,
    page,
    setParams,
    total,
    loading,
    reloadData,
    sort,
    sortDir,
    setSorting,
    responsibility,
    edited,
    setEdited,
    selectMode,
    setSelectMode,
    selectedEsgs,
    setSelectedEsgs,
    deleteEsgsList,
    esgQuestionId,
    sites,
    setGroupOpen,
    closedYear,
    manageEsgPermission,
}) => {
    const { showModal } = useModals();
    const { notifyError, notifySuccess, notifyInfo } = useNotify();

    const deleteEsg = useCallback((id) => {
        doSwaggerCall('Esgs', 'deleteEsgsByList', { idList: [id] })
            .then(() => {
                notifySuccess('ESGs deleted');
                reloadData();
            })
            .catch((err) => {
                notifyError(err);
            });
    }, []);

    const handleSelectAll = (ev) => {
        if (ev.target.checked) {
            setSelectedEsgs(rows);
            return;
        }
        setSelectedEsgs([]);
    };

    const assignedSkeleton = !responsibility ? 11 : 10;
    const rowsIdList = rows?.map((r) => r.id);

    // TODO: cant upload the same docs again
    const uploadEventHandler = async (event) => {
        if (!event.target.files || !event.target.files.length) {
            return;
        }
        const f = event.target.files[0];
        notifyInfo(`Upload started for ${f.name}`);
        await uploadDocumentToMultipleEsgs(
            f,
            selectedEsgs.map((e) => e.id)
        );
        notifyInfo(`Upload finished for ${f.name}`);
        reloadData();
        setSelectedEsgs([]);
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Grid item xs={12}>
            {edited === `new - ${esgQuestionId}` && (
                <Paper
                    sx={{
                        mb: 5,
                        p: 2,
                    }}
                    style={{
                        border: '2px solid #0f304a',
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <EditEsgPage
                                newEsg
                                sites={sites}
                                reloadData={reloadData}
                                setEdited={setEdited}
                                esgQuestionId={esgQuestionId}
                                setGroupOpen={setGroupOpen}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            )}
            <TableContainer>
                <Table aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>
                            {selectMode && manageEsgPermission && !closedYear && (
                                <TableCell>
                                    <Checkbox
                                        checked={
                                            rowsIdList?.length > 0 &&
                                            selectedEsgs.length === rowsIdList.length
                                        }
                                        onChange={(ev) => handleSelectAll(ev)}
                                        indeterminate={
                                            selectedEsgs.length > 0 &&
                                            selectedEsgs.length < rowsIdList.length
                                        }
                                    />
                                </TableCell>
                            )}
                            <SortableTableCell
                                arrow={sort === 'question' ? sortDir : ''}
                                onClick={() => setSorting('question')}
                            >
                                Question
                            </SortableTableCell>
                            <SortableTableCell
                                arrow={sort === 'sitename' ? sortDir : ''}
                                onClick={() => setSorting('sitename')}
                            >
                                Site
                            </SortableTableCell>
                            <TableCell>Values</TableCell>
                            {responsibility !== 'assignedToMe' && (
                                <SortableTableCell
                                    arrow={sort === 'assignee' ? sortDir : ''}
                                    onClick={() => setSorting('assignee')}
                                    justifyContent="center"
                                >
                                    Assignee
                                </SortableTableCell>
                            )}
                            {responsibility !== 'ownedByMe' && (
                                <SortableTableCell
                                    arrow={sort === 'owner' ? sortDir : ''}
                                    onClick={() => setSorting('owner')}
                                    justifyContent="center"
                                >
                                    Owner
                                </SortableTableCell>
                            )}
                            <SortableTableCell
                                arrow={sort === 'documents' ? sortDir : ''}
                                onClick={() => setSorting('documents')}
                            >
                                Doc.
                            </SortableTableCell>
                            <SortableTableCell
                                arrow={sort === 'isestimated' ? sortDir : ''}
                                onClick={() => setSorting('isestimated')}
                                width="6%"
                            >
                                Est.
                            </SortableTableCell>
                            <TableCell>Notes</TableCell>
                            <TableCell align="center">Completion</TableCell>
                            <SortableTableCell
                                arrow={sort === 'mark_completed' ? sortDir : ''}
                                onClick={() => setSorting('mark_completed')}
                                justifyContent="center"
                            >
                                Mark completed
                            </SortableTableCell>
                            {!closedYear && (
                                <TableCell align="right">
                                    {manageEsgPermission &&
                                        (!selectMode ? (
                                            <MultipleLineSelect setSelectMode={setSelectMode} />
                                        ) : (
                                            <div>
                                                <Tooltip title="Cancel selection">
                                                    <IconButton
                                                        onClick={() => {
                                                            setSelectMode(false);
                                                        }}
                                                    >
                                                        <DeselectIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Select action">
                                                    <IconButton onClick={handleClick}>
                                                        <MoreHorizIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <TableSelectDropdown
                                                    open={open}
                                                    anchorEl={anchorEl}
                                                    onClose={handleClose}
                                                    reloadData={reloadData}
                                                    selectedEsgs={selectedEsgs}
                                                    setSelectedEsgs={setSelectedEsgs}
                                                    deleteEsgsList={deleteEsgsList}
                                                    uploadEventHandler={uploadEventHandler}
                                                    permissions={{
                                                        manageEsgPermission,
                                                    }}
                                                />
                                            </div>
                                        ))}
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <SkeletonTable
                                rows={limit}
                                cells={selectMode ? assignedSkeleton + 1 : assignedSkeleton}
                            />
                        ) : (
                            rows?.map((row) => (
                                <React.Fragment key={row.id}>
                                    <TableRow style={edited === row.id ? { display: 'none' } : {}}>
                                        {selectMode && (
                                            <TableCell>
                                                <Checkbox
                                                    checked={selectedEsgs
                                                        .map((e) => e.id)
                                                        ?.includes(row.id)}
                                                    onChange={() =>
                                                        setSelectedEsgs(
                                                            selectedEsgs
                                                                ?.map((e) => e.id)
                                                                .includes(row.id)
                                                                ? selectedEsgs.filter(
                                                                      (e) => e.id !== row.id
                                                                  )
                                                                : [
                                                                      ...selectedEsgs,
                                                                      {
                                                                          id: row.id,
                                                                      },
                                                                  ]
                                                        )
                                                    }
                                                />
                                            </TableCell>
                                        )}
                                        <Tooltip
                                            title={[
                                                row.esg_category,
                                                row.esg_subcategory,
                                                row.esg_disclosure,
                                            ]
                                                .filter((e) => e)
                                                .join(' > ')}
                                        >
                                            <TableCell>{row.esg_question}</TableCell>
                                        </Tooltip>
                                        <TableCell>
                                            {row.site_id === null ? 'Corporate' : row.site_name}
                                        </TableCell>
                                        <TableCell>
                                            {row.values.length > 0
                                                ? row.values.map((field) => (
                                                      <p>
                                                          {
                                                              row.esg_question_fields.find(
                                                                  (e) => e.id === field?.id
                                                              )?.label
                                                          }
                                                          :{' '}
                                                          {field.value ? (
                                                              <b>
                                                                  {row.esg_question_fields.find(
                                                                      (e) => e.id === field?.id
                                                                  )?.type === 'number'
                                                                      ? formatNumber(field.value)
                                                                      : field.value}{' '}
                                                                  {field?.measure &&
                                                                  field.measure !== WITHOUT_UNIT
                                                                      ? field.measure
                                                                      : ''}
                                                              </b>
                                                          ) : (
                                                              '-'
                                                          )}
                                                      </p>
                                                  ))
                                                : `-`}
                                        </TableCell>
                                        <ResponsibilityTableCell
                                            row={row.assignedUser}
                                            responsibility={responsibility}
                                            type="assignedToMe"
                                        />
                                        <ResponsibilityTableCell
                                            row={row.ownerUser}
                                            responsibility={responsibility}
                                            type="ownedByMe"
                                        />
                                        <TableCell>
                                            <Grid container spacing={1} flexWrap="nowrap">
                                                <Grid item />
                                                <TableDetails
                                                    row={row}
                                                    onlyDocs
                                                    onlyEstimated={false}
                                                />
                                            </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid container spacing={1} flexWrap="nowrap">
                                                <Grid item />
                                                <TableDetails
                                                    row={row}
                                                    onlyDocs={false}
                                                    onlyEstimated
                                                />
                                            </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid
                                                container
                                                spacing={1}
                                                alignItems="center"
                                                flexWrap="nowrap"
                                            >
                                                <Grid item />
                                                <TableDetails
                                                    row={row}
                                                    onlyDocs={false}
                                                    onlyEstimated={false}
                                                />
                                            </Grid>
                                        </TableCell>
                                        <TableCell align="center">{`${row.field_filled_out}/${row.field_count}`}</TableCell>
                                        <TableCell align="center">
                                            {row.mark_completed ? (
                                                <CheckIcon color="success" />
                                            ) : (
                                                <CloseIcon color="error" />
                                            )}
                                        </TableCell>
                                        <TableCell align="right" width="10%">
                                            <Tooltip title={closedYear ? 'View' : 'Edit'}>
                                                <IconButton
                                                    onClick={() => {
                                                        setEdited(row.id);
                                                    }}
                                                    disabled={selectMode}
                                                >
                                                    {closedYear ? (
                                                        <RemoveRedEyeIcon />
                                                    ) : (
                                                        <EditIcon />
                                                    )}
                                                </IconButton>
                                            </Tooltip>
                                            {!closedYear && (
                                                <IconButton
                                                    onClick={() => {
                                                        showModal(MODAL_TYPE.CONFIRMATION, {
                                                            title: `Are you sure you want to remove ESG?`,
                                                            content: 'This action is irreversible.',
                                                            confirm: () => deleteEsg(row.id),
                                                        });
                                                    }}
                                                    disabled={selectMode}
                                                >
                                                    <Tooltip title="Delete">
                                                        <DeleteIcon />
                                                    </Tooltip>
                                                </IconButton>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    {edited === row.id && (
                                        <TableRow
                                            className={edited === row.id ? 'editedTableRow' : ''}
                                        >
                                            <TableCell colSpan={selectMode ? 11 : 10}>
                                                <EditEsgPage
                                                    newEsg={false}
                                                    esgIdParam={row.id}
                                                    reloadData={reloadData}
                                                    setEdited={setEdited}
                                                    esgQuestionId={esgQuestionId}
                                                    setGroupOpen={setGroupOpen}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </React.Fragment>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                count={total || 0}
                rowsPerPage={limit}
                component="div"
                page={page}
                onPageChange={(_, newPage) => handlePageChange(newPage, setParams, limit)}
                rowsPerPageOptions={TABLE_ROW_COUNT_OPTIONS}
                onRowsPerPageChange={(event) => handleChangeRowsPerPage(event, setParams)}
            />
        </Grid>
    );
};

export default EsgsTable;
