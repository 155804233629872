import {
    ButtonGroup,
    Checkbox,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeselectIcon from '@mui/icons-material/Deselect';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router-dom';
import RuleIcon from '@mui/icons-material/Rule';
import SkeletonTable from '../../../components/atom/SkeletonTable';
import { doSwaggerCall } from '../../../hooks/useApi';
import useNotify from '../../../hooks/useNotify';
import SortableTableCell from '../../../components/atom/SortableTableCell';
import { notificationTypeMapping } from '../../../helpers/notificationHelper';
import { TABLE_ROW_COUNT_OPTIONS } from '../../../helpers/constans';
import { useGlobalFilters } from '../../../hooks/useGlobalFilters';
import { handleChangeRowsPerPage, handlePageChange } from '../../../helpers/handleTableFunctions';
import MultipleLineSelect from '../../../components/atom/MultipleLineSelect';
import { DATALINE_GROUPS } from '../../../helpers/groups';
import { useEsgFrameworkFilters } from '../../../hooks/useEsgFrameworkFilters';
import { useEsgFilters } from '../../../hooks/useEsgFilters';

// helper function to get the notification type
const getNotificationType = (notification) => {
    let notificationType = '';
    // if notification contains 'assigned' it is a type of assigned notification
    if (notification.includes('assigned')) {
        notificationType = 'assignedtome';
    }
    // if notification contains 'owned' it is a type of owned notification
    if (notification.includes('owned')) {
        notificationType = 'ownedbyme';
    }
    if (notification.includes('edited') || notification.includes('deleted')) {
        notificationType = 'ownedbyme';
    }
    return notificationType;
};

const NotificationsTable = ({
    rows,
    loading,
    limit,
    setParams,
    total,
    page,
    reloadData,
    sort,
    sortDir,
    setSorting,
}) => {
    const { notifyError, notifySuccess } = useNotify();
    const navigate = useNavigate();
    const { year, setYear, setSourceFilters, setSiteFilters, setSelectedTags, setSelectedSites } =
        useGlobalFilters();
    const { setEsgFilters, setEdited } = useEsgFilters();
    const { setEsgFrameworkFilters, setEdited: setEditedEsgFramework } = useEsgFrameworkFilters();
    const [selectMode, setSelectMode] = useState(false);
    const [selectedNotifications, setSelectedNotifications] = useState([]);

    const rowsIdList = rows?.map((r) => r.id);

    const handleSelectAll = (ev) => {
        if (ev.target.checked) {
            const newSelectedIds = rows.map((notification) => notification.id);
            setSelectedNotifications(newSelectedIds);
            return;
        }
        setSelectedNotifications([]);
    };

    const changeSeen = useCallback((id) => {
        doSwaggerCall('Notifications', 'editNotificationsByList', { selectedIdList: [id] })
            .then(() => {
                notifySuccess('Notification updated');

                reloadData();
            })
            .catch((err) => {
                notifyError(err);
            });
    }, []);

    const changeSeenSelected = useCallback(() => {
        doSwaggerCall('Notifications', 'editNotificationsByList', {
            selectedIdList: selectedNotifications,
            multiple: true,
        })
            .then(() => {
                notifySuccess('Notifications updated');
                reloadData();
            })
            .catch((err) => {
                notifyError(err);
            });
    }, [selectedNotifications]);

    const navigateToDatalineOrEsg = useCallback(
        (initYear, yearParam, notificationType, itemName) => {
            // set global year filter
            if (yearParam !== initYear) {
                setYear(yearParam);
            }
            // notificationType can be 'esg_...' or 'dataline_...' and handle it separately
            if (notificationType.includes('dataline')) {
                // reset global (source + site) filters
                setSourceFilters({
                    scopeFilter: null,
                    categoryFilter: null,
                    subcategoryFilter: null,
                    emissionSourceFilter: null,
                });
                setSiteFilters({ siteFilter: [], siteNamesFilter: [] });
                setSelectedTags([]);
                setSelectedSites([]);

                navigate(
                    `/datalines/${getNotificationType(
                        notificationType
                    )}/?search=${itemName}&groupby=${DATALINE_GROUPS.NONE}`
                );
            } else if (notificationType.includes('esg')) {
                // reset global esg filters
                setEsgFilters({
                    esgCategoryFilter: null,
                    esgSubcategoryFilter: null,
                    esgDisclosureFilter: null,
                    esgQuestionFilter: null,
                });
                setEsgFrameworkFilters({
                    esgFrameworkFilter: null,
                    esgFrameworkNameFilter: null,
                });
                setEdited(false);
                setEditedEsgFramework(false);
                // site filters
                setSiteFilters({ siteFilter: [], siteNamesFilter: [] });
                setSelectedTags([]);
                setSelectedSites([]);

                navigate(
                    `/esgs/${getNotificationType(notificationType)}/?search=${itemName}&groupby=${
                        DATALINE_GROUPS.NONE
                    }`
                );
            }
        },
        []
    );

    return (
        <Grid item xs={12}>
            <TableContainer component={Paper}>
                <Table aria-label="custom table">
                    <TableHead>
                        <TableRow>
                            {selectMode && (
                                <TableCell>
                                    <ButtonGroup>
                                        <Checkbox
                                            checked={
                                                rowsIdList?.length > 0 &&
                                                selectedNotifications.length === rowsIdList.length
                                            }
                                            onChange={(ev) => handleSelectAll(ev)}
                                            indeterminate={
                                                selectedNotifications.length > 0 &&
                                                selectedNotifications.length < rowsIdList.length
                                            }
                                        />
                                        <Tooltip title="Cancel selection">
                                            <IconButton
                                                onClick={() => {
                                                    setSelectMode(false);
                                                }}
                                            >
                                                <DeselectIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </ButtonGroup>
                                </TableCell>
                            )}
                            <SortableTableCell
                                arrow={sort === 'notification_type' ? sortDir : ''}
                                onClick={() => setSorting('notification_type')}
                            >
                                Notification type
                            </SortableTableCell>
                            <SortableTableCell
                                arrow={sort === 'notification_data' ? sortDir : ''}
                                onClick={() => setSorting('notification_data')}
                            >
                                Notification data
                            </SortableTableCell>
                            <TableCell>Year</TableCell>
                            <SortableTableCell
                                arrow={sort === 'seen' ? sortDir : ''}
                                onClick={() => setSorting('seen')}
                                justifyContent="center"
                            >
                                Seen
                            </SortableTableCell>
                            <TableCell>
                                {!selectMode ? (
                                    <MultipleLineSelect setSelectMode={setSelectMode} />
                                ) : (
                                    <Tooltip title="Seen selected notifications">
                                        <IconButton
                                            onClick={() => {
                                                changeSeenSelected(selectedNotifications);
                                            }}
                                            disabled={selectedNotifications.length === 0}
                                        >
                                            <RuleIcon />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <SkeletonTable rows={limit} cells={selectMode ? 6 : 5} />
                        ) : (
                            rows?.map((row) => (
                                <TableRow>
                                    {selectMode && (
                                        <TableCell>
                                            <Checkbox
                                                checked={selectedNotifications.includes(row.id)}
                                                onChange={() =>
                                                    setSelectedNotifications(
                                                        selectedNotifications.includes(row.id)
                                                            ? selectedNotifications.filter(
                                                                  (e) => e !== row.id
                                                              )
                                                            : [...selectedNotifications, row.id]
                                                    )
                                                }
                                            />
                                        </TableCell>
                                    )}
                                    <TableCell>
                                        {notificationTypeMapping(
                                            row.notification_type,
                                            row.notification_data.name
                                        )}
                                    </TableCell>
                                    <TableCell>{row.notification_data.name}</TableCell>
                                    <TableCell>{row.notification_data.year}</TableCell>
                                    <TableCell align="center" width="10%">
                                        {row.seen ? (
                                            <Tooltip title="Change to unseen" placement="right">
                                                <IconButton onClick={() => changeSeen(row.id)}>
                                                    <CheckIcon color="success" />
                                                </IconButton>
                                            </Tooltip>
                                        ) : (
                                            <Tooltip title="Change to seen" placement="right">
                                                <IconButton onClick={() => changeSeen(row.id)}>
                                                    <CloseIcon color="error" />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        <Tooltip
                                            title={`Navigate to ${row.notification_data?.name}`}
                                        >
                                            <IconButton
                                                disabled={
                                                    selectMode ||
                                                    row.notification_type.includes('remove') ||
                                                    row.notification_data.year === undefined
                                                }
                                                onClick={() => {
                                                    navigateToDatalineOrEsg(
                                                        year, // init year
                                                        row.notification_data.year, // new year
                                                        row.notification_type,
                                                        row.notification_data.name
                                                    );
                                                }}
                                            >
                                                <VisibilityIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                count={total || 0}
                rowsPerPage={limit}
                component="div"
                page={page}
                onPageChange={(_, newPage) => handlePageChange(newPage, setParams, limit)}
                rowsPerPageOptions={TABLE_ROW_COUNT_OPTIONS}
                onRowsPerPageChange={(event) => handleChangeRowsPerPage(event, setParams)}
            />
        </Grid>
    );
};

export default NotificationsTable;
