import React, { useState } from 'react';
import {
    DialogActions,
    DialogContentText,
    DialogContent,
    DialogTitle,
    Dialog,
    TextField,
    Grid,
} from '@mui/material';
import AutocompleteSelect from '../components/atom/AutoCompleteSelect';
import { userAutocomplete } from '../autocomplete';
import { doSwaggerCall } from '../hooks/useApi';
import useNotify from '../hooks/useNotify';
import CommonButton from '../components/atom/CommonButton';

const AssignModal = ({
    title,
    content,
    helperText,
    showModal,
    MODAL_TYPE,
    selectedLines,
    setSelectedLines,
    reloadData,
    label,
    isEsg = false,
}) => {
    const closeAction = () => showModal(MODAL_TYPE.NONE);
    const [userId, setUserId] = useState(null);
    const { notifyError, notifySuccess } = useNotify();
    const userType = label === 'Owner' ? 'owner' : 'assignedto';

    const assignList = async () => {
        try {
            const endpoint = isEsg ? 'Esgs' : 'Datalines';
            const operation = isEsg ? 'assignEsgsByList' : 'assignDatalinesByList';

            await doSwaggerCall(endpoint, operation, {
                idList: selectedLines,
                userId: userId || 0,
                userType,
            });

            const successMessage = isEsg
                ? `ESGs assigned (${label})`
                : `Datalines assigned (${label})`;
            notifySuccess(successMessage);
            reloadData();
            setSelectedLines([]);
        } catch (err) {
            notifyError(err);
        }
    };

    return (
        <Dialog
            open
            onClose={closeAction}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
                <Grid>
                    <AutocompleteSelect
                        sx={{ mt: 1.5, mb: 0.75 }}
                        initCall={userAutocomplete.init}
                        autocompleteCall={userAutocomplete.search}
                        value={userId}
                        setValue={(v) => setUserId(v)}
                    >
                        <TextField label={label} />
                    </AutocompleteSelect>
                </Grid>
                <DialogContentText id="alert-dialog-description">{helperText}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <CommonButton
                    onClick={closeAction}
                    autoFocus
                    label="Cancel"
                    buttonType="secondary"
                />
                <CommonButton
                    onClick={() => {
                        assignList();
                        showModal(MODAL_TYPE.NONE);
                    }}
                    label="Yes"
                    buttonType="primary"
                />
            </DialogActions>
        </Dialog>
    );
};

export default AssignModal;
