import React, { useEffect, useState } from 'react';
import { Grid, LinearProgress } from '@mui/material';
import useNotify from '../../../hooks/useNotify';
import { doSwaggerCall } from '../../../hooks/useApi';
import CategoryOrTagOrSourceOrSupplierBarChart from './charts/CategoryOrTagOrSourceOrSupplierBarChart';

const BySelectedIntensityPerSites = ({
    intensityMetric,
    siteIdList,
    years,
    scope,
    catId,
    setChartData,
}) => {
    const { notifyError } = useNotify();
    const [loading, setLoading] = useState(true);
    const [barData, setBarData] = useState([]);

    useEffect(() => {
        doSwaggerCall('Dashboards', 'getDataForIntensityPerSitesBarChart', {
            intensityMetric,
            siteIdList,
            years,
            scope,
            catId,
        })
            .then((res) => {
                setChartData(res.intensityData);
                setBarData(res);
                setLoading(false);
            })
            .catch((error) => {
                notifyError(error);
            });
    }, [intensityMetric, siteIdList, years, scope, catId, setLoading, setBarData, setChartData]);

    return (
        <Grid item xs={12}>
            {barData?.length === 0 ? (
                <Grid item>
                    {loading ? <LinearProgress sx={{ mt: 0.25 }} /> : <h5>No data available</h5>}
                </Grid>
            ) : (
                <CategoryOrTagOrSourceOrSupplierBarChart
                    data={barData.intensityData}
                    keys={years}
                    indexBy="site"
                    axisLeftLegend="CO2e (mt)"
                    selectedLevel={barData.selectedLevel}
                    name={barData.name}
                />
            )}
        </Grid>
    );
};

export default BySelectedIntensityPerSites;
