import React from 'react';
import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
} from '@mui/material';
import SkeletonTable from '../../../components/atom/SkeletonTable';
import { TABLE_ROW_COUNT_OPTIONS } from '../../../helpers/constans';
import { handleChangeRowsPerPage, handlePageChange } from '../../../helpers/handleTableFunctions';
import SortableTableCell from '../../../components/atom/SortableTableCell';

const AirportsTable = ({
    rows,
    limit,
    page,
    setParams,
    total,
    loading,
    sort,
    sortDir,
    setSorting,
}) => {
    return (
        <Grid item xs={12}>
            <Table>
                <TableHead>
                    <TableRow>
                        <SortableTableCell
                            arrow={sort === 'origin' ? sortDir : ''}
                            onClick={() => setSorting('origin')}
                        >
                            Origin
                        </SortableTableCell>
                        <SortableTableCell
                            arrow={sort === 'origin_city' ? sortDir : ''}
                            onClick={() => setSorting('origin_city')}
                        >
                            Origin city
                        </SortableTableCell>
                        <SortableTableCell
                            arrow={sort === 'dest' ? sortDir : ''}
                            onClick={() => setSorting('dest')}
                        >
                            Destination
                        </SortableTableCell>
                        <SortableTableCell
                            arrow={sort === 'dest_city' ? sortDir : ''}
                            onClick={() => setSorting('dest_city')}
                        >
                            Destination city
                        </SortableTableCell>
                        <TableCell>Distance (miles)</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading ? (
                        <SkeletonTable rows={limit} cells={5} />
                    ) : (
                        rows?.map((row) => (
                            <React.Fragment key={row.id}>
                                <TableRow>
                                    <TableCell>{row.origin}</TableCell>
                                    <TableCell>{row.origin_city}</TableCell>
                                    <TableCell>{row.dest}</TableCell>
                                    <TableCell>{row.dest_city}</TableCell>
                                    <TableCell>{row.distance}</TableCell>
                                </TableRow>
                            </React.Fragment>
                        ))
                    )}
                </TableBody>
            </Table>
            <TablePagination
                count={total || 0}
                rowsPerPage={limit}
                component="div"
                page={page}
                onPageChange={(_, newPage) => handlePageChange(newPage, setParams, limit)}
                rowsPerPageOptions={TABLE_ROW_COUNT_OPTIONS}
                onRowsPerPageChange={(event) => handleChangeRowsPerPage(event, setParams)}
            />
        </Grid>
    );
};

export default AirportsTable;
