import { Alert, Grid } from '@mui/material';
import React from 'react';

const ClosedYearAlert = ({ forScenario = false }) => {
    const ALERT_TEXT = forScenario
        ? `Baseline year should be a closed year. Please contact your company admin to close the year.`
        : `The year that you are viewing is locked. If you want to modify anything, ask an admin to unlock it first.`;

    return (
        <Grid item xs={12}>
            <Alert sx={{ mt: 0.5 }} severity="info">
                {ALERT_TEXT}
            </Alert>
        </Grid>
    );
};

export default ClosedYearAlert;
