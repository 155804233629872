import { Form, Formik } from 'formik';
import { Typography, Grid, TextField, FormControlLabel, Checkbox, InputLabel } from '@mui/material';
import React, { useState } from 'react';
import AddItemButton from '../../../components/atom/AddItemButton';
import AutocompleteSelect from '../../../components/atom/AutoCompleteSelect';
import { userAutocomplete, siteAutocomplete } from '../../../autocomplete';
import DocumentField from '../../datalineedit/components/DocumentField';
import LastModified from '../../datalineedit/components/LastModified';
import CommonButton from '../../../components/atom/CommonButton';
import EsgQuestionField from '../../../modals/components/esg/EsgQuestionField';
import EsgHelperIcon from '../../../components/atom/EsgHelperIcon';
import validateEsg from '../helpers/validateEsg';
import FreeSoloEsgTagAutoComplete from '../../../components/atom/FreeSoloEsgTagAutoComplete';

function EsgForm({ onSubmit, esg, newEsg, setEdited, percentages, setPercentages, closedYear }) {
    const [showNotes, setShowNotes] = useState(!(esg.notes === null || esg.notes === ''));
    const [showDocuments, setShowDocuments] = useState(esg?.documents?.length > 0);
    const [showAssign, setShowAssign] = useState(!!esg?.assigned_user?.user_id);
    const [showOwner, setShowOwner] = useState(!!esg?.owner_user?.user_id);
    const [showEsgTags, setShowEsgTags] = useState(esg?.esgTags.length > 0);
    const [showDate, setShowDate] = useState(!!esg?.to_date || !!esg?.from_date);

    const validate = (values) => {
        return validateEsg(values);
    };

    return (
        <Formik onSubmit={onSubmit} enableReinitialize initialValues={esg} validate={validate}>
            {({ isSubmitting, values, setFieldValue, touched, errors }) => (
                <Form>
                    <Grid container spacing={2}>
                        <Grid item container spacing={2}>
                            <Grid item container justifyContent="space-between">
                                <Grid item>
                                    {newEsg && (
                                        <Grid item sx={{ pb: 1 }}>
                                            <Typography variant="h4" style={{ fontSize: '14px' }}>
                                                Add new ESG
                                            </Typography>
                                        </Grid>
                                    )}
                                    <Grid item>
                                        <Typography variant="h6">
                                            {values.esg_question.name}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                {values.esg_question.guide_note && (
                                    <Grid item>
                                        <EsgHelperIcon
                                            title={values.esg_question.name}
                                            guideNote={values.esg_question.guide_note}
                                            esgQuestionId={values.esg_question_id}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                        {values.esg_question.fields?.map(
                            ({ id, label, type, defaultMeasure }, idx) => (
                                <EsgQuestionField
                                    id={id}
                                    label={label}
                                    type={type}
                                    defaultMeasure={defaultMeasure}
                                    idx={idx}
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    closedYear={closedYear}
                                />
                            )
                        )}
                        <Grid item container spacing={2}>
                            <Grid item style={{ flex: '1 1 0' }}>
                                <AutocompleteSelect
                                    size="small"
                                    initCall={siteAutocomplete.init}
                                    autocompleteCall={siteAutocomplete.search}
                                    value={values?.site_id}
                                    setValue={(ev) => {
                                        setFieldValue('site_id', ev);
                                    }}
                                    disabled={closedYear}
                                >
                                    <TextField label="Site" required />
                                </AutocompleteSelect>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={!!values.is_estimated}
                                        onChange={(e) =>
                                            setFieldValue('is_estimated', !!e.target.checked)
                                        }
                                    />
                                }
                                label="Estimated value"
                                labelPlacement="end"
                                disabled={closedYear}
                            />
                        </Grid>
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={!!values.mark_completed}
                                        onChange={(e) =>
                                            setFieldValue('mark_completed', !!e.target.checked)
                                        }
                                    />
                                }
                                label="Mark completed"
                                labelPlacement="end"
                                disabled={closedYear}
                            />
                        </Grid>
                        {showNotes && (
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    multiline
                                    autoComplete="off"
                                    name="notes"
                                    label="Notes"
                                    value={values.notes || ''}
                                    onChange={(ev) => setFieldValue('notes', ev.target.value)}
                                    disabled={closedYear}
                                />
                            </Grid>
                        )}
                        {showAssign && (
                            <Grid item xs={6}>
                                <AutocompleteSelect
                                    initCall={userAutocomplete.init}
                                    autocompleteCall={userAutocomplete.search}
                                    value={values.assigned_user?.user_id}
                                    setValue={(ev) => {
                                        setFieldValue('assigned_user.user_id', ev);
                                    }}
                                    disabled={closedYear}
                                >
                                    <TextField label="Assigned user" />
                                </AutocompleteSelect>
                            </Grid>
                        )}
                        {showOwner && (
                            <Grid item xs={6}>
                                <AutocompleteSelect
                                    initCall={userAutocomplete.init}
                                    autocompleteCall={userAutocomplete.search}
                                    value={values?.owner_user?.user_id}
                                    setValue={(ev) => {
                                        setFieldValue('owner_user.user_id', ev);
                                    }}
                                    disabled={closedYear}
                                >
                                    <TextField label="Owner" />
                                </AutocompleteSelect>
                            </Grid>
                        )}
                        {showDate && (
                            <>
                                <Grid item xs={6}>
                                    <InputLabel>From date</InputLabel>
                                    <TextField
                                        fullWidth
                                        type="date"
                                        id="from_date"
                                        name="from_date"
                                        value={values.from_date}
                                        onChange={(ev) =>
                                            setFieldValue('from_date', ev.target.value)
                                        }
                                        error={touched.from_date && Boolean(errors.from_date)}
                                        helperText={touched.from_date ? errors.from_date : ''}
                                        disabled={closedYear}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabel>To date</InputLabel>
                                    <TextField
                                        fullWidth
                                        type="date"
                                        id="to_date"
                                        name="to_date"
                                        value={values.to_date}
                                        onChange={(ev) => setFieldValue('to_date', ev.target.value)}
                                        error={touched.to_date && Boolean(errors.to_date)}
                                        helperText={touched.to_date ? errors.to_date : ''}
                                        disabled={closedYear}
                                    />
                                </Grid>
                            </>
                        )}
                        {showDocuments && (
                            <DocumentField
                                values={values}
                                setFieldValue={setFieldValue}
                                closedYear={closedYear}
                                isEsg
                            />
                        )}
                        {showEsgTags && (
                            <FreeSoloEsgTagAutoComplete
                                fullWidth
                                label="Tags"
                                value={values?.esgTags}
                                setValue={(newValue) => setFieldValue('esgTags', newValue)}
                                percentages={percentages}
                                setPercentages={setPercentages}
                                closedYear={closedYear}
                            >
                                <TextField label="Esg tags" required />
                            </FreeSoloEsgTagAutoComplete>
                        )}
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                {!showNotes && !closedYear && (
                                    <AddItemButton
                                        label="Note"
                                        onClick={() => setShowNotes(true)}
                                    />
                                )}
                                {!showDocuments && !closedYear && (
                                    <AddItemButton
                                        label="Document"
                                        onClick={() => setShowDocuments(true)}
                                    />
                                )}
                                {!showAssign && !closedYear && (
                                    <AddItemButton
                                        label="Assign"
                                        onClick={() => setShowAssign(true)}
                                    />
                                )}
                                {!showOwner && !closedYear && (
                                    <AddItemButton
                                        label="Owner"
                                        onClick={() => setShowOwner(true)}
                                    />
                                )}
                                {!showDate && !closedYear && (
                                    <AddItemButton
                                        label="Dates (from-to)"
                                        onClick={() => setShowDate(true)}
                                    />
                                )}
                                {!showEsgTags && !closedYear && (
                                    <AddItemButton
                                        label="Esg tags"
                                        onClick={() => setShowEsgTags(true)}
                                    />
                                )}
                                <Grid item style={{ flex: '1 1 0' }}>
                                    <Grid container spacing={2} justifyContent="flex-end">
                                        <Grid item>
                                            <CommonButton
                                                fullWidth
                                                onClick={() => setEdited(false)}
                                                label="Cancel"
                                                buttonType="secondary"
                                            />
                                        </Grid>
                                        <Grid item>
                                            <CommonButton
                                                fullWidth
                                                disabled={isSubmitting || closedYear}
                                                type="submit"
                                                label="Save"
                                                buttonType="primary"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {values.modified_user?.user_name && values?.modified_at && (
                            <Grid item xs={12} pt={0}>
                                <LastModified
                                    modifiedUser={values?.modified_user}
                                    modifiedAt={values?.modified_at}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Form>
            )}
        </Formik>
    );
}

export default EsgForm;
