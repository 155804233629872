import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import CustomTreeItem from '../../../components/atom/CustomTreeItem';
import transformTreeToFlat from '../../../components/esgrequirementshierarchy/helpers/transformTreeToFlat';
import EsgSubcategoryLevel from '../../../components/esgrequirementshierarchy/components/EsgSubcategoryLevel';

function EsgCategoryLevel({ checkedData, esg_category, setCheckedData, getCheckedChildrenNumber }) {
    const checkedChildren = getCheckedChildrenNumber(esg_category);
    const esgCategoryLabel =
        checkedChildren === 0
            ? `${esg_category.name}`
            : `${esg_category.name} (+ ${checkedChildren} more)`;
    return (
        <CustomTreeItem
            nodeId={`esg_category - ${esg_category.id}`}
            label={
                <FormControlLabel
                    label={esgCategoryLabel}
                    labelPlacement="end"
                    control={
                        <Checkbox
                            key={`${esg_category.id} - ${esg_category.name}`}
                            checked={
                                !!checkedData.find(
                                    (c) => c.id === esg_category.id && c.type === 'category'
                                )
                            }
                            onChange={() => {
                                setCheckedData((old) => {
                                    const findCurrent = old.find(
                                        (item) =>
                                            item.id === esg_category.id && item.type === 'category'
                                    );
                                    if (findCurrent) {
                                        // find current item's children
                                        const children = transformTreeToFlat(esg_category.children);
                                        return old.filter(
                                            (data) =>
                                                data !== findCurrent &&
                                                !children.some(
                                                    (child) =>
                                                        child.id === data.id &&
                                                        child.type === data.type
                                                )
                                        );
                                    }
                                    return [
                                        ...old,
                                        {
                                            type: 'category',
                                            id: esg_category.id,
                                        },
                                        ...transformTreeToFlat(esg_category.children),
                                    ];
                                });
                            }}
                        />
                    }
                />
            }
        >
            {esg_category.children.map((esgSubcategory) => (
                <EsgSubcategoryLevel
                    key={`${esgSubcategory.id} - ${esgSubcategory.name}`}
                    checkedData={checkedData}
                    esg_subcategory={esgSubcategory}
                    setCheckedData={setCheckedData}
                    getCheckedChildrenNumber={getCheckedChildrenNumber}
                    isScenario
                />
            ))}
        </CustomTreeItem>
    );
}

export default EsgCategoryLevel;
