import React from 'react';
import { Grid, Tab, Tabs } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { PERMISSIONS } from '../../helpers/constans';
import { ESG_FEATURE_ENABLED } from '../../config';

const ScenarioTabSwitcher = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { userPermissions } = useAuth();

    let selectedTab = -1;
    if (pathname.startsWith('/scenarios')) {
        selectedTab = 0;
    }
    if (pathname.startsWith('/esgscenarios')) {
        selectedTab = 1;
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Tabs
                    value={selectedTab}
                    centered
                    TabIndicatorProps={{
                        style: {
                            // remove underline since position not follows sidebar change
                            display: 'none',
                        },
                    }}
                >
                    <Tab
                        label="GHG"
                        onClick={(e) => {
                            navigate('/scenarios');
                            e.stopPropagation();
                        }}
                    />
                    <Tab
                        label="ESG"
                        onClick={(e) => {
                            navigate('/esgscenarios');
                            e.stopPropagation();
                        }}
                        disabled={
                            !ESG_FEATURE_ENABLED ||
                            !userPermissions.includes(PERMISSIONS.MANAGE_ESGS)
                        }
                    />
                </Tabs>
            </Grid>
        </Grid>
    );
};

export default ScenarioTabSwitcher;
