import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ComponentTitle from '../../components/ComponentTitle';
import useNotify from '../../hooks/useNotify';
import { useGlobalFilters } from '../../hooks/useGlobalFilters';
import useSorting from '../../hooks/useSorting';
import { useApi } from '../../hooks/useApi';
import { TABLE_ROW_COUNT_DEFAULT } from '../../helpers/constans';
import BlankEsgsTable from './components/BlankEsgsTable';

const EsgProgressTrackerPage = () => {
    const { notifyError } = useNotify();
    const { year, site } = useGlobalFilters();
    const { sort, sortDir, setSorting } = useSorting(['created_at', 'ASC']);
    const [{ limit, page }, setParams] = useState({ limit: TABLE_ROW_COUNT_DEFAULT, page: 0 });

    const [data, loading, error] = useApi('Esgs', 'getBlankEsgs', {
        year,
        sort,
        sortDir,
        limit,
        siteIdList: site,
        offset: page * limit,
    });

    useEffect(() => {
        if (error) {
            notifyError(error);
        }
    }, [error, notifyError]);

    return (
        <Grid container alignContent="flex-start">
            <Grid container item xs={12} justifyContent="space-between" spacing={0}>
                <Grid item>
                    <ComponentTitle variant="h4" title="ESG Progress Tracker" />
                </Grid>
            </Grid>
            <BlankEsgsTable
                rows={data?.esgs}
                total={data?.total}
                limit={limit}
                page={page}
                sort={sort}
                sortDir={sortDir}
                setSorting={setSorting}
                setParams={setParams}
                loading={loading}
            />
        </Grid>
    );
};

export default EsgProgressTrackerPage;
