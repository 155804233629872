import { Alert, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React from 'react';
import ClosedYearAlert from '../../../components/atom/ClosedYearAlert';

const BasicScenarioSettings = ({ values, setFieldValue, errors, touched, availableYears }) => {
    const baselineYearError = availableYears.filter((e) => e.state === 'closed').length === 0;
    return (
        <>
            {!baselineYearError && <ClosedYearAlert forScenario />}
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label="Name"
                    autoComplete="off"
                    value={values.name}
                    onChange={(ev) => setFieldValue('name', ev.target.value)}
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name ? errors.name : ''}
                />
            </Grid>
            {baselineYearError ? (
                <Grid item xs={12}>
                    <Alert sx={{ mt: 1 }} severity="warning">
                        To create a Scenario, you need to have a closed year. Please contact your
                        company admin to close the year.
                    </Alert>
                </Grid>
            ) : (
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <InputLabel id="year-select">Baseline year</InputLabel>
                        <Select
                            fullWidth
                            labelId="baseline-year-select"
                            id="demo-simple-select"
                            value={values.baseline_year || ''}
                            size="small"
                            label="Baseline year"
                            onChange={(ev) => {
                                setFieldValue('baseline_year', ev.target.value);
                            }}
                            error={touched.baseline_year && Boolean(errors.baseline_year)}
                            helperText={touched.baseline_year ? errors.baseline_year : ''}
                        >
                            {availableYears.map((yearItem) => (
                                <MenuItem
                                    key={yearItem.year}
                                    value={yearItem.year}
                                    disabled={yearItem.state !== 'closed'}
                                >
                                    {yearItem.year}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            )}
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    autoComplete="off"
                    label="Target year"
                    value={values.target_year}
                    onChange={(ev) => setFieldValue('target_year', ev.target.value)}
                    error={touched.target_year && Boolean(errors.target_year)}
                    helperText={touched.target_year ? errors.target_year : ''}
                />
            </Grid>
        </>
    );
};

export default BasicScenarioSettings;
