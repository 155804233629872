import React, { useState } from 'react';
import {
    Paper,
    Table,
    TableBody,
    TableContainer,
    Grid,
    TableHead,
    TableRow,
    TableCell,
} from '@mui/material';
import { getSubTree } from '../../../helpers/rebalance';
import { doSwaggerCall } from '../../../hooks/useApi';
import useNotify from '../../../hooks/useNotify';
import CommonButton from '../../../components/atom/CommonButton';
import BreadCrumbTitle from '../../scenarios/components/BreadCrumbTitle';
import EsgScenarioLine from './EsgScenarioLine';
import ScenarioLine from './ScenarioLine';

export function getBreadcrumbFromPath(root, path) {
    const innerPath = [...path];
    const breadcrumb = [
        {
            name: 'Global',
        },
    ];
    let pointer = root;
    while (innerPath.length > 0) {
        pointer = pointer.children[innerPath.shift()];
        breadcrumb.push({
            name: pointer.name || 'Global',
        });
    }
    return { breadcrumbList: breadcrumb };
}

function EsgScenarioForm({ scenario, setScenario, setEsgNote }) {
    const root = scenario.target_hierarchy;
    const [currentPath, setCurrentPath] = useState([]);
    const [currentPathIds, setCurrentPathIds] = useState([]);
    const { notifySuccess, notifyError } = useNotify();
    const currentLevel = getSubTree({ children: root }, currentPath);
    const { breadcrumbList } = getBreadcrumbFromPath({ children: root }, currentPath);
    const esgLevel = breadcrumbList.length === 5;

    const setNote = (path, newNote) => {
        const deepCopyTree = JSON.parse(JSON.stringify({ children: root }));
        const pointer = getSubTree(deepCopyTree, path);
        pointer.note = newNote;
        setScenario({ ...scenario, target_hierarchy: deepCopyTree.children });
    };

    const setTarget = (path, newTargetValue) => {
        const deepCopyTree = JSON.parse(JSON.stringify({ children: root }));
        const pointer = getSubTree(deepCopyTree, path);
        pointer.targetValue = newTargetValue;
        setScenario({ ...scenario, target_hierarchy: deepCopyTree.children });
    };

    const saveHierarchy = async () => {
        try {
            await doSwaggerCall(
                'EsgScenarios',
                'editEsgScenario',
                { id: scenario.id },
                {
                    name: scenario.name,
                    targetHierarchy: scenario.target_hierarchy,
                }
            );
            notifySuccess('ESG scenario updated');
        } catch (err) {
            notifyError(err);
        }
    };

    return (
        <>
            <Grid
                container
                item
                xs={12}
                justifyContent="space-between"
                spacing={0}
                sx={{ pb: 2, pt: 2 }}
                style={{ alignItems: 'center' }}
            >
                <BreadCrumbTitle
                    breadcrumbList={breadcrumbList}
                    path={currentPath}
                    setCurrentPath={setCurrentPath}
                    setCurrentPathIds={setCurrentPathIds}
                    currentPathIds={currentPathIds}
                    isEsgScenario
                />
                <Grid item>
                    <CommonButton
                        fullWidth
                        onClick={() => saveHierarchy()}
                        label="Save hierarchy"
                        buttonType="primary"
                    />
                </Grid>
            </Grid>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {!esgLevel ? (
                                <TableCell>Name</TableCell>
                            ) : (
                                <>
                                    <TableCell>Question</TableCell>
                                    <TableCell>Site</TableCell>
                                    <TableCell />
                                </>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {esgLevel
                            ? currentLevel.children.map((item, idx) => {
                                  return (
                                      <EsgScenarioLine
                                          key={item.id}
                                          item={item}
                                          idx={idx}
                                          setEsgNote={setEsgNote}
                                          currentPath={currentPath}
                                          questionName={currentLevel.name}
                                          setTarget={(newValue) => {
                                              setTarget([...currentPath, idx], newValue);
                                          }}
                                          baselineYear={scenario.baseline_year}
                                          targetYear={scenario.target_year}
                                      />
                                  );
                              })
                            : currentLevel.children.map((item, idx) => {
                                  return (
                                      <ScenarioLine
                                          key={`${item.id} - ${currentPath}`}
                                          id={item.id}
                                          name={item.name}
                                          navigateToChildren={() => {
                                              setCurrentPath((old) => [...old, idx]);
                                              setCurrentPathIds((prev) => [...prev, item.id]);
                                          }}
                                          note={item.note}
                                          setNote={(newValue) => {
                                              setNote([...currentPath, idx], newValue);
                                          }}
                                      />
                                  );
                              })}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default EsgScenarioForm;
